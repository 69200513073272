import React from "react";
import styled from "styled-components";
import Container from "../../common/Container";
import Row from "../../common/Row";

import medicalHistory from "../../../images/home/benefit-onboarding.png";
import access247 from "../../../images/home/benefit-247.png";
import devices from "../../../images/home/benefit-devices.png";
import quick from "../../../images/home/benefit-quick.png";

const DATA = [
  {
    id: "onboarding",
    title: "Concierge Onboarding",
    description: "We spend time getting to know your medical history.",
    image: medicalHistory,
  },
  {
    id: "access-247",
    title: "24/7 Access to Physicians",
    description: "No more waking up at 3AM without any options.",
    image: access247,
  },
  {
    id: "devices",
    title: "Easy to Use Medical Devices",
    description: "Better data means better care.",
    image: devices,
  },
  {
    id: "short-wait-times",
    title: "Short Wait Times",
    description: "Minutes stand between you and better health.",
    image: quick,
  },
];

const BetterBenefits = () => (
  <Section id="better-benefits">
    <Container>
      <Content>
        <h2>Better Benefits</h2>
        <Row gap={60}>
          {DATA.map((item) => (
            <Item id={item.id} key={item.id}>
              <img src={item.image} alt={item.title} />
              <h4>{item.title}</h4>
              <p>{item.description}</p>
            </Item>
          ))}
        </Row>
      </Content>
    </Container>
  </Section>
);

const Section = styled.section`
  background-color: white;

  h2 {
    font-size: 46px;
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 768px) {
    h2 {
      text-align: center;
      font-size: 36px;
    }
  }
`;

const Content = styled.div`
  padding: 60px 0;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;

  img {
    display: block;
    width: 110px;
    margin-bottom: 20px;
    object-fit: contain;
  }

  h4 {
    font-size: 20px;
    font-family: ${(props) => props.theme.fonts.secondary};
    font-weight: bold;
    margin-bottom: 17px;
  }

  p {
    font-size: 16px;
  }
`;

export default BetterBenefits;
