import React from "react";
import styled from "styled-components";
import Container from "./Container";
import Column from "./Column";
import Row from "./Row";

import wave from "../../images/home/main-gray-wave.png";

const Symptoms = () => (
  <Section id="chronic-illness-symptoms">
    <Container>
      <Content>
        <Column align="center">
          <h2>Chronic Illness Support</h2>
          <p>
            Over 90% of our patient population has 2 or more chronic illnesses,
            so we are confident in our ability to care for your symptoms.
          </p>
          <p>
            <b>Covering symptoms related to:</b>
          </p>
        </Column>
        <Row gap={50} mobileGap={1} valign="top">
          <Column align="right">
            <ul>
              <li>Alzheimer's Disease and Related Dementia</li>
              <li>Arthritis (Osteoarthritis and Rheumatoid)</li>
              <li>Atrial Fibrillation</li>
              <li>Hypertension (High blood pressure)</li>
              <li>Ischemic Heart Disease</li>
              <li>Osteoporosis</li>
              <li>Depression</li>
              <li>Diabetes</li>
            </ul>
          </Column>
          <Column align="left">
            <ul>
              <li>Heart Failure</li>
              <li>Asthma</li>
              <li>Hyperlipidemia (High cholesterol)</li>
              <li>Cancer</li>
              <li>Chronic Kidney Disease</li>
              <li>Chronic Obstructive Pulmonary Disease</li>
              <li>Stroke</li>
              <li>Hepatitis (Chronic Viral B & C)</li>
            </ul>
          </Column>
        </Row>
      </Content>
    </Container>
  </Section>
);

const Section = styled.section`
  background-color: ${(props) => props.theme.backgroundColors.gray};

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-80%);
    content: "";
    display: block;
    width: 100%;
    height: 150px;
    background-image: url(${wave});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
    pointer-events: none;
  }

  h2,
  p {
    color: ${(props) => props.theme.textColors.primary};
  }

  h2 {
    font-size: 46px;
    margin-bottom: 40px;
    text-align: center;
  }

  p {
    font-size: 20px;
    text-align: center;
    max-width: 640px;

    &:last-child {
      margin-top: 20px;
    }
  }

  ul,
  li {
    list-style-type: none;
  }

  li {
    font-size: 17px;
    color: ${(props) => props.theme.textColors.primary};
    margin-top: 18px;
  }

  @media only screen and (max-width: 768px) {
    h2 {
      font-size: 36px;
      margin-bottom: 32px;
    }
    p {
      font-size: 17px;
    }

    ul {
      margin: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    &::after {
      height: 40px;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 992px) {
    &::after {
      height: 75px;
    }
  }
`;

const Content = styled.div`
  padding: 90px 0 110px;

  @media only screen and (max-width: 768px) {
    padding-bottom: 90px;
  }
`;

export default Symptoms;
