import React from "react";
import styled from "styled-components";

const Contact = () => {
  const btnClick = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSeNVoIyQvG7EGVLNjJP2mLyZUsmL1jRh8Qd4MFGcd-W2hEgfg/viewform?usp=sf_link"
    );
  };

  return (
    <Container>
      <Section>
        <Row>
          <Content>
            <Title>Contact Us</Title>
            <Block>
              <Box>
                <p>
                  <address>
                    <b>BetterMedics</b>
                    <br />
                    13001 Cedar Road
                    <br />
                    Cleveland Heights, Ohio 44118
                  </address>
                </p>
                <p>
                  Phone:
                  <br />
                  <a href="tel:+2162738400">(216) 273-8400</a>
                </p>
                <p>
                  Fax:
                  <br />
                  (216) 435-7338
                </p>
              </Box>
            </Block>
            <Block>
              <p>Sign up to learn more about our services:</p>
              <Button onClick={btnClick}>Learn More</Button>
            </Block>
          </Content>
        </Row>
      </Section>
    </Container>
  );
};

const Title = styled.h1`
  margin-bottom: 20px;
`;

const Container = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;

  p,
  li {
    line-height: 1.8;
    font-size: 15px;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.secondary};
  }

  ol {
    margin-bottom: 40px;
  }

  li {
    color: ${(props) => props.theme.textColors.secondary};
    padding-left: 10px;
    margin-bottom: 10px;
  }
`;

const Section = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
`;

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  width: calc(100% - 64px);

  @media (max-width: 59.9375em) {
    margin: 0 auto;
    max-width: 500px;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 60px;
`;

const Block = styled.div`
  margin: 20px 0;
  padding-bottom: 10px;

  p {
    margin-bottom: 30px;
  }

  strong {
    padding-right: 10px;
  }

  ul {
    margin-bottom: 30px;
  }

  address {
    display: block;
    margin-top: 20px;
    font-style: normal;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  border: 2px solid ${(props) => props.theme.colors.border};
  border-radius: 30px;
  width: 300px;
`;

const Button = styled.button`
  display: inline-block;
  width: 180px;
  height: 48px;
  line-height: 48px;
  text-decoration: none;
  background: ${(props) =>
    `linear-gradient(215deg, ${props.theme.colors.secondary}, ${props.theme.colors.primary})`};
  border-radius: 6px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  color: white;
  letter-spacing: 0.35px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background: ${(props) =>
      `linear-gradient(195deg, ${props.theme.colors.secondary}, ${props.theme.colors.primary})`};
  }

  @media only screen and (max-width: 550px) {
    align-self: center;
  }
`;

export default Contact;
