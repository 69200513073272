import React from "react";
import styled from "styled-components";
import Button from "../../common/Button";
import Column from "../../common/Column";
import Container from "../../common/Container";
import Row from "../../common/Row";

import medicalKit from "../../../images/home/bettermedics-medical-kit.jpg";
import wave from "../../../images/home/main-hero-wave.png";

const Pricing = () => (
  <Section id="pricing">
    <Container>
      <Content>
        <Row gap={60} mobileGap={30}>
          <Column align="left" width="35%">
            <h2>
              Unlimited calling.
              <br />
              Flat monthly fee.
              <br />
              No hidden costs.
            </h2>
            <p>
              Without questions surrounding the cost of care, you can take
              comfort in knowing you can call any time, it's all included.
            </p>
          </Column>
          <Column width="65%">
            <Row gap={20}>
              <Column width="50%">
                <Box>
                  <h6>Introductory Pricing</h6>
                  <Price>
                    $199<span>/ person / mo</span>
                  </Price>
                  <p>
                    For a flat rate per month, call as much as you need. Nothing
                    will be billed to insurance.
                  </p>
                  <Button onClick={() => window.openForm()}>Get started</Button>
                </Box>
              </Column>
              <Column width="50%">
                <Box>
                  <h6>Medical Kit</h6>
                  <ImageWrapper>
                    <img src={medicalKit} alt="BetterMedics: Medical Kit" />
                  </ImageWrapper>

                  <FakeBtn>Free with Enrollment</FakeBtn>
                </Box>
              </Column>
            </Row>
          </Column>
        </Row>
      </Content>
    </Container>
  </Section>
);

const Section = styled.section`
  background: #faf6ee;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100px;
    background-image: url(${wave});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    transform: rotate(180deg);
    pointer-events: none;
  }

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100px;
    background-image: url(${wave});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    pointer-events: none;
  }
`;

const Content = styled.div`
  padding: 180px 0 200px;

  h2 {
    font-size: 46px;
    margin-bottom: 30px;
    line-height: 1.3;
  }
  p {
    max-width: 380px;
    font-size: 20px;
  }

  @media only screen and (max-width: 768px) {
    text-align: center;
    padding: 120px 0;

    h2 {
      font-size: 36px;
    }
    p {
      font-size: 18px;
    }
  }
`;

const Price = styled.div`
  font-size: 36px;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: bold;
  color: ${(props) => props.theme.textColors.primary};
  margin-bottom: 24px;

  span {
    font-size: 15px;
    font-weight: 500;
    color: ${(props) => props.theme.textColors.secondary};
    padding-left: 4px;
  }
`;

const FakeBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${(props) => props.theme.colors.border};
  height: 44px;
  border-radius: 4px;

  color: ${(props) => props.theme.textColors.secondary};
  font-size: 15px;
  font-weight: 500;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;

  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 8px;

  width: 100%;
  height: 100%;
  min-height: 360px;

  h6 {
    font-size: 15px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.primary};
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 24px;
  }

  p {
    font-size: 16px;
    flex: 1;
  }

  img {
    display: block;
    object-fit: contain;
    width: 100%;
    max-height: 200px;
  }

  @media only screen and (max-width: 768px) {
    text-align: left;
    min-height: initial;

    p {
      font-size: 15px;
      margin-bottom: 30px;
    }

    img {
      margin-bottom: 24px;
    }
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
`;

export default Pricing;
