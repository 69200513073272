import React from "react";
import styled from "styled-components";
import Button from "../../common/Button";
import Container from "../../common/Container";
import Column from "../../common/Column";

import wave from "../../../images/home/main-gray-wave.png";

const PatientPopulations = () => (
  <Section id="patient-populations">
    <Container>
      <Content>
        <Column>
          <h1>We focus on complex and elderly patient populations.</h1>
          <p>
            We're a new layer of care in the home that provides companionship
            and clinical support when it's needed the most.
          </p>
          <Button href="/about">Learn more about us</Button>
        </Column>
      </Content>
    </Container>
  </Section>
);

const Section = styled.section`
  background: #faf6ee;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100px;
    background-image: url(${wave});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    transform: scaleX(-100%) rotate(180deg) translateY(100%);
  }

  @media only screen and (max-width: 400px) {
    &::after {
      top: 60px;
      height: 60px;
    }
  }
`;

const Content = styled.div`
  padding: 90px 0;
  text-align: center;

  h1 {
    font-size: 56px;
    margin-bottom: 36px;
    line-height: 1.1;
    max-width: 840px;
  }

  p {
    font-size: 24px;
    padding-right: 40px;
    line-height: 1.5;
    margin-bottom: 36px;
    max-width: 840px;
  }

  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 36px;
      margin-bottom: 30px;
    }
    p {
      font-size: 18px;
      padding-right: 0;
    }
  }
`;

export default PatientPopulations;
