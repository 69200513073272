import React from "react";
import styled from "styled-components";
import Hero from "../components/common/Hero";
import BetterBenefits from "../components/sections/home/BetterBenefits";
import CareForThoseYouLove from "../components/sections/home/CareForThoseYouLove";
import HowItWorks from "../components/sections/home/HowItWorks";
import PatientPopulations from "../components/sections/home/PatientPopulations";
import MedicalProfessionals from "../components/sections/home/MedicalProfessionals";
import Pricing from "../components/sections/home/Pricing";
import MedicalKit from "../components/sections/home/MedicalKit";
import FAQs from "../components/sections/home/FAQs";

const hero = require("../images/home/main-hero.webp");

const HERO = {
  title: "Healthcare Companionship for Complex Patients",
  description:
    "The majority of healthcare happens in the home. We're here to support you, 24/7.",
  btnLabel: "Get Started",
  image: hero,
};

const Home = () => {
  const onBtnClick = () => {
    window.openForm();
  };

  return (
    <Page id="home">
      <Hero {...HERO} onClick={onBtnClick} />
      <BetterBenefits />
      <CareForThoseYouLove />
      <HowItWorks />
      <PatientPopulations />
      <MedicalProfessionals />
      <Pricing />
      <MedicalKit />
      <FAQs />
      {/* <EmailForm /> */}
    </Page>
  );
};

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
`;

export default Home;
