import React from "react";
import styled from "styled-components";

import Container from "../components/common/Container";
import FAQsList from "../components/common/FAQsList";

const FAQsPage = () => (
  <Page id="resources">
    <Section id="FAQs">
      <Container>
        <Content>
          <h2>Frequently Asked Questions</h2>
          <FAQsList />
        </Content>
      </Container>
    </Section>
  </Page>
);

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;

  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const Section = styled.section`
  h2 {
    font-size: 46px;
    margin-bottom: 80px;
    text-align: center;
  }
`;

const Content = styled.div`
  padding: 90px 0 110px;
`;

export default FAQsPage;
