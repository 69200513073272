import React from "react";
import styled from "styled-components";
import Container from "../../common/Container";
import Row from "../../common/Row";

import videoCalling from "../../../images/home/howitworks-videocall.png";
import careplan from "../../../images/home/howitworks-careplan.png";
import greeted from "../../../images/home/howitworks-greeted.png";
import physician from "../../../images/home/howitworks-physician.png";
import wave from "../../../images/home/main-gray-wave.png";

const DATA = [
  {
    id: "video-calling",
    title: "Video Calling from Your Device",
    description:
      "Call using your iPhone or iPad. BetterMedics medical devices connect automatically via bluetooth.",
    image: videoCalling,
  },
  {
    id: "greeted",
    title: "Greeted & Guided",
    description:
      "Our CareLine Operators will quickly answer your call and guide you through collecting your vitals.",
    image: greeted,
  },
  {
    id: "physician",
    title: "Connected to a Physician",
    description:
      "Our Physicians are ready to speak with you and provide a comprehensive virtual exam for peace of mind.",
    image: physician,
  },
  {
    id: "careplan",
    title: "Get a Personalized Care Plan",
    description:
      "An after visit summary is delivered to you with care actions and details from your visit.",
    image: careplan,
  },
];

const HowItWorks = () => (
  <Section id="how-it-works">
    <Container>
      <Content>
        <h2>How it works</h2>
        <Row gap={100} valign="top" mobileGap={40}>
          {DATA.map((item) => (
            <Item id={item.id} key={item.id}>
              <img src={item.image} alt={item.title} />
              <h4>{item.title}</h4>
              <p>{item.description}</p>
            </Item>
          ))}
        </Row>
      </Content>
    </Container>
  </Section>
);

const Section = styled.section`
  background-color: ${(props) => props.theme.backgroundColors.gray};

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-80%);
    content: "";
    display: block;
    width: 100%;
    height: 175px;
    background-image: url(${wave});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
    pointer-events: none;
  }

  h2 {
    font-size: 46px;
    margin-bottom: 80px;
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    h2 {
      font-size: 36px;
      margin-bottom: 50px;
    }
  }

  @media only screen and (max-width: 400px) {
    &::after {
      height: 45px;
    }
  }
`;

const Content = styled.div`
  padding: 90px 0;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;

  img {
    display: block;
    width: 115px;
    margin-bottom: 20px;
    object-fit: contain;
  }

  h4 {
    font-size: 20px;
    font-family: ${(props) => props.theme.fonts.secondary};
    font-weight: bold;
    margin-bottom: 17px;
    max-width: 210px;
  }

  p {
    font-size: 16px;
    color: ${(props) => props.theme.textColors.primary};
  }
`;

export default HowItWorks;
