import React from "react";
import styled from "styled-components";
import { MdCheckCircle } from "react-icons/md";

import Container from "../../common/Container";
import Column from "../../common/Column";
import Row from "../../common/Row";

import easyToUse from "../../../images/patients/easy-to-use.webp";
import timely from "../../../images/patients/timely-care.webp";

const Copy = () => (
  <Section id="patients-copy">
    <Container>
      <Content>
        <Group>
          <Row gap={90} reverseMobile mobileGap={40}>
            <Column align="left" width="45%">
              <h3>Easy to Use</h3>
              <p>
                Our system was designed with ease of use in mind, so we think
                you'll be impressed with the experience. We guide you - every
                part of the way.
              </p>
              <ul>
                <li>
                  <MdCheckCircle />
                  <p>Password assistance</p>
                </li>
                <li>
                  <MdCheckCircle />
                  <p>No typing needed</p>
                </li>
                <li>
                  <MdCheckCircle />
                  <p>Support in using medical devices</p>
                </li>
              </ul>
            </Column>
            <Column width="55%">
              <Image src={easyToUse} />
            </Column>
          </Row>
        </Group>
        <Group>
          <Row gap={90} mobileGap={40}>
            <Column width="55%">
              <Image src={timely} />
            </Column>
            <Column align="left" width="45%">
              <h3>Timely</h3>
              <p>
                It's the middle of the night and your symptoms are getting
                worse. You can try the nurse on call - but they will likely send
                you to the ER. We're here for you.
              </p>
              <ul>
                <li>
                  <MdCheckCircle />
                  <p>24/7 Access</p>
                </li>
                <li>
                  <MdCheckCircle />
                  <p>Short Wait Times</p>
                </li>
                <li>
                  <MdCheckCircle />
                  <p>
                    Real-time Vitals provide better insight into your health
                    status
                  </p>
                </li>
              </ul>
            </Column>
          </Row>
        </Group>
      </Content>
    </Container>
  </Section>
);

const Group = styled.div`
  margin: 120px 0;

  &:first-of-type {
    margin-top: 0;
  }

  @media only screen and (max-width: 768px) {
    &:last-of-type {
      margin-bottom: 30px;
    }
  }
`;

const Section = styled.section`
  background: white;

  h3 {
    font-size: 40px;
    margin-bottom: 14px;
    line-height: 1.3;
  }

  p {
    font-size: 22px;
    padding-right: 40px;
    line-height: 1.5;
    margin-bottom: 30px;
  }

  ul {
    padding-left: 15px;
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${(props) => props.theme.textColors.secondary};
    list-style-type: none;
    gap: 10px;
    margin-bottom: 12px;

    svg {
      font-size: 20px;
    }

    p {
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 0;
      text-align: left;
      flex: 1;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 992px) {
    p {
      padding-right: 0;
    }
    ul {
      padding-left: 10px;
    }
  }

  @media only screen and (max-width: 768px) {
    text-align: center;

    h3 {
      font-size: 32px;
    }

    p {
      font-size: 17px;
      padding-right: 0;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      list-style-type: none;
      max-width: 280px;
      p {
        font-size: 17px;
      }
    }
  }
`;

const Content = styled.div`
  padding: 120px 0;

  @media only screen and (max-width: 992px) {
    padding: 60px 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 80px 0;
  }
`;

const Image = styled.img`
  display: block;
  object-fit: contain;
  max-width: 100%;
  border-radius: 80px;
  overflow: hidden;

  box-shadow: 30px 20px 0 rgba(244, 235, 222, 0.5);

  @media only screen and (max-width: 992px) {
    box-shadow: 15px 10px 0 rgba(244, 235, 222, 0.5);
    border-radius: 40px;
  }

  @media only screen and (max-width: 768px) {
    box-shadow: none;
  }
`;

export default Copy;
