import React from "react";
import styled from "styled-components";
import Container from "./Container";

const Callout = ({ text }) => (
  <Section>
    <Container>
      <Content>
        <h1>{text}</h1>
      </Content>
    </Container>
  </Section>
);

const Content = styled.div``;
const Section = styled.section`
  background-color: ${(props) => props.theme.backgroundColors.stone};

  ${Content} {
    padding: 120px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 40px;
      line-height: 1.3;
      margin-bottom: 0;
      text-align: center;
      max-width: 970px;
    }
  }

  @media only screen and (max-width: 768px) {
    ${Content} {
      padding: 100px 10px;
      h1 {
        font-size: 32px;
      }
    }
  }
`;

export default Callout;
