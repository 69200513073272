import React from "react";
import styled from "styled-components";

import Header from "../components/Header";
import Footer from "../components/Footer";

const InteriorLayout = ({ children }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const isWebView = queryParameters.get("webview");

  return (
    <Container>
      {isWebView ? null : <Header />}

      <Content>{children}</Content>
      {isWebView ? null : <Footer mode="internal" />}
    </Container>
  );
};

const Content = styled.div`
  flex: 1;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export default InteriorLayout;
