import React from "react";
import styled from "styled-components";
import hero from "../images/careers-hero.jpg";
import candidates from "../images/careers-candidates.png";
import Container from "../components/common/Container";
import Row from "../components/common/Row";
import Column from "../components/common/Column";

const Careers = () => (
  <>
    <Hero>
      <Container>
        <Row gap={80}>
          <Column align="left" width="50%">
            <h1>Careers at BetterMedics</h1>
            <p>
              Thanks for your interest in BetterMedics. We are launching a
              six-month private research study in order to test new healthcare
              delivery models within the home. The pilot census will begin with
              150 home-bound patients, of which the median age is 71 and 85%
              have at least two chronic illnesses. We believe that a 24-hour
              care line approach will strengthen home-support, improve quality
              of life and avoid unnecessary ER visits.
            </p>
          </Column>
          <Column width="50%">
            <HeroImage>
              <Image src={hero} />
            </HeroImage>
          </Column>
        </Row>
      </Container>
    </Hero>
    <Candidates>
      <Container>
        <Row gap={80}>
          <Column align="left">
            <h2>Candidates</h2>
            <p>
              We are looking for individuals who are passionate about helping
              people and excited about innovation in healthcare. Our virtual
              interdisciplinary care team will be employed or contracted on a
              six month basis, with the potential for extension.
            </p>
          </Column>
          <Column>
            <CandidatesImage>
              <Image src={candidates} />
            </CandidatesImage>
          </Column>
        </Row>
      </Container>
    </Candidates>
    <Jobs>
      <Container>
        <h1>Now Hiring For</h1>
        <p>
          Check out the positions below in order to apply through our website
        </p>
        <PositionsWrapper>
          <Position>
            <a href="/careers/care-line-operator">Care Line Operator</a>
            <label>Employee</label>
          </Position>
          <Position>
            <a href="/careers/physician-contractor">
              On-Call Telehealth Physician
            </a>
            <label>Contractor</label>
          </Position>
        </PositionsWrapper>
        <p>
          Email <a href="mailto:HR@bettermedics.org">HR@bettermedics.org</a>{" "}
          with any questions.{" "}
        </p>
      </Container>
    </Jobs>
  </>
);

const HeroImage = styled.div`
  height: auto;
  max-width: 45vw;
  border-radius: 65px;
  overflow: hidden;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

const Section = styled.section`
  position: relative;
  display: block;
  padding: 120px 0;
  overflow: hidden;
`;

const Hero = styled(Section)`
  padding: 80px 0;
  min-height: 40vh;

  p {
    font-size: 20px;
    max-width: 500px;
  }
`;

const CandidatesImage = styled.div`
  height: auto;
  max-width: 30vw;

  @media (max-width: 59.9375em) {
    max-width: 100%;
    margin-top: 36px;
  }
`;

const Candidates = styled(Section)`
  background-color: ${(props) => props.theme.backgroundColors.dark};

  h2 {
    font-size: 42px;
  }

  h2,
  p {
    color: white;
  }

  p {
    font-size: 19px;
    max-width: 500px;
  }
`;

const Jobs = styled(Section)`
  text-align: center;
  background-color: ${(props) => props.theme.backgroundColors.secondary};

  h1 {
    margin-bottom: 24px;
  }
  p,
  p a {
    color: ${(props) => props.theme.textColors.primary};
  }
`;

const PositionsWrapper = styled.div`
  margin: 36px 0;
`;

const Position = styled.span`
  display: block;
  padding: 20px 0;

  a {
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: ${(props) => props.theme.colors.secondary};
    }
  }
  label {
    display: block;
    color: #999faf;
    font-size: 17px;
    margin-top: 5px;
  }
`;

export default Careers;
