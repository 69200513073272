import React from "react";
import styled from "styled-components";
import Container from "../../common/Container";
import FAQsList from "../../common/FAQsList";

const DATA = [
  "whoWillBeCaringForMe",
  "isBetterMedicsRightForMe",
  "isBetterMedicsASubstituteForPrimaryCare",
];

const FAQs = () => (
  <Section id="FAQs">
    <Container>
      <Content>
        <h2>Frequently Asked Questions</h2>
        <FAQsList data={DATA} gray />
      </Content>
    </Container>
  </Section>
);

const Section = styled.section`
  background-color: ${(props) => props.theme.backgroundColors.gray};

  h2 {
    font-size: 46px;
    margin-bottom: 80px;
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    h2 {
      font-size: 36px;
      margin-bottom: 40px;
    }
  }
`;

const Content = styled.div`
  padding: 0;
`;

export default FAQs;
