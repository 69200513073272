import React from "react";
import styled from "styled-components";

const Container = ({ children }) => <Wrapper>{children}</Wrapper>;

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 768px) {
    width: 750px;
  }

  @media only screen and (min-width: 992px) {
    width: 970px;
  }

  @media only screen and (min-width: 1200px) {
    width: 1170px;
  }
`;

export default Container;
