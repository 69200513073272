import React from "react";
import styled from "styled-components";

const NonDiscrimination = () => (
  <Container>
    <Section>
      <Row>
        <Content>
          <Title>Notice of Non-Discrimination</Title>
          <Block>
            <p>
              <b>THIS NOTICE INFORMS INDIVIDUALS ABOUT NONDISCRIMINATION</b>
            </p>
            <p>
              BetterMedics, Inc. does not exclude, deny benefits to, or
              otherwise discriminate against any person on the basis of race,
              color, national origin, disability, or age in admission to,
              participation in, or receipt of the services and benefits under
              any of its programs and activities, whether carried out by
              BetterMedics, Inc. directly or through a contractor or any other
              entity with which BetterMedics, Inc. arranges to carry out its
              services and activities.
            </p>
            <p>
              BetterMedics, Inc. will take appropriate steps to ensure that
              persons with disabilities have an equal opportunity to receive our
              services. All necessary technology and services shall be provided
              without cost to our clients.
            </p>
          </Block>
        </Content>
      </Row>
    </Section>
  </Container>
);

const Title = styled.h1`
  margin-bottom: 20px;
`;

const Container = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;

  p,
  li {
    line-height: 1.8;
    font-size: 15px;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.secondary};
  }

  ol {
    margin-bottom: 40px;
  }

  li {
    color: ${(props) => props.theme.textColors.secondary};
    padding-left: 10px;
    margin-bottom: 10px;
  }
`;

const Section = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
`;

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  width: calc(100% - 64px);

  @media (max-width: 59.9375em) {
    margin: 0 auto;
    max-width: 500px;
  }
`;

const Content = styled.div`
  position: relative;
  flex: 0 1 auto;
`;

const Block = styled.div`
  margin: 20px 0;
  padding-bottom: 10px;

  p {
    margin-bottom: 30px;
  }

  strong {
    padding-right: 10px;
  }

  ul {
    margin-bottom: 30px;
  }

  address {
    display: block;
    margin-top: 20px;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export default NonDiscrimination;
