import React from "react";
import styled from "styled-components";
import Hero from "../components/common/Hero";
import Symptoms from "../components/common/Symptoms";
import Copy from "../components/sections/caregivers/Copy";

const hero = require("../images/caregivers/caregivers-hero.webp");

const HERO = {
  title: "Caregivers",
  description:
    "Whether you are near or far, we can help you take care of your Loved-One.",
  btnLabel: "Let us Help",
  image: hero,
};

const Caregivers = () => (
  <Page id="caregivers">
    <Hero {...HERO} onClick={() => window.openForm()} />
    <Copy />
    <Symptoms />
  </Page>
);

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
`;

export default Caregivers;
