import React from "react";
import styled from "styled-components";
import Hero from "../components/common/Hero";
import Symptoms from "../components/common/Symptoms";
import Copy from "../components/sections/patients/Copy";

const hero = require("../images/patients/chronically-ill-patients.webp");

const HERO = {
  title: "Patient Care",
  description:
    "It's the middle of the night and you're just not feeling right. You want to check your vitals and speak to a physician now. We're here for you.",
  btnLabel: "Let us Help",
  image: hero,
};

const Patients = () => {
  const onBtnClick = () => {};

  return (
    <Page id="caregivers">
      <Hero {...HERO} onClick={() => window.openForm()} />
      <Copy />
      <Symptoms />
    </Page>
  );
};

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
`;

export default Patients;
