import React from "react";
import styled from "styled-components";

const InformedConsent = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const isLegal = queryParameters.get("legal");

  return (
    <Container legal={isLegal}>
      <Section>
        <Row>
          <Content>
            <Title>Informed Consent for Telehealth Services</Title>
            <Date>Last Updated: January 24, 2022</Date>
            <Block>
              <h2>Introduction to Our Services</h2>
              <p>
                The Healthcare Providers at BetterMedics, LLC ("
                <strong>BetterMedics</strong>," "<strong>we</strong>," "
                <strong>us</strong>," or "<strong>our</strong>") provide
                telehealth services by using interactive audio and video
                technologies where you and your Healthcare Provider are not in
                the same physical location. Your Healthcare Providers may
                include Medical Doctors (M.D.) and Doctors of Osteopathic
                Medicine (D.O.). You can form an ongoing treatment relationship
                with a BetterMedics Healthcare Provider, however, your initial
                visit may begin as a consultation (e.g. to determine the most
                appropriate treatment setting for you to receive care) and will
                not necessarily give rise to an ongoing treatment relationship
                until you engage in additional sessions. Your consultation or
                sessions may be recorded with your permission to maintain as a
                part of your medical record. You should seek follow-up care when
                recommended, following a consultation or session. In case of an
                emergency, dial 911 or go directly to the nearest emergency room
                or hospital. It is important that you understand the benefits
                and potentials risks of using telehealth for your medical care
                and treatment.
              </p>
            </Block>
            <Block>
              <h2>Anticipated Benefits of Using Telehealth</h2>
              <ul>
                <li>
                  Improved access to medical care by enabling a patient to
                  remain at home, office, or other remote location while
                  consulting or interacting in a session with a Healthcare
                  Provider.
                </li>
                <li>
                  Reduced risks of travel, exposure to pathogens or ill patients
                  at in-person facilities.
                </li>
                <li>
                  More time-efficient medical evaluation and continued health
                  management.
                </li>
              </ul>
            </Block>
            <Block>
              <h2>Potential Risks of Using Telehealth:</h2>
              <ul>
                <li>
                  Information transmitted to Healthcare Providers during a
                  telehealth visit may be more limited than in- person visits in
                  certain ways, which may impact diagnosis, treatment, or for
                  further recommendation relating to referrals.
                </li>
                <li>
                  Disruptions can occur due to malfunctions of the electronic
                  equipment or internet connection, hacking, or unauthorized
                  access. Such disruptions may result in delays in medical
                  evaluation and treatment. If this happens, you may be
                  contacted by your Healthcare Provider by phone or other means
                  of communication.
                </li>
                <li>
                  Failure to provide a complete medical history, list of current
                  medications, and other relevant medical information may result
                  in adverse health outcomes or reduce overall effectiveness of
                  treatment.
                </li>
                <li>
                  Although BetterMedics incorporates strong security protocols
                  to protect the confidentiality of your health information, in
                  rare instances, security protocols may fail, causing a breach
                  of confidentiality.
                </li>
                <li>
                  There may be limited availability of certain laboratory,
                  x-ray, EKG, and other testing to assist your Healthcare
                  Provider in certain diagnosis and treatment and may require
                  coordinating care with your primary care provider.
                </li>
                <li>
                  There may be unknown risks or negative outcomes associated
                  with the use of telehealth for certain health conditions.
                </li>
              </ul>
              <p>
                <strong>
                  <i>
                    By accepting this consent, you acknowledge that you
                    understand, accept, and agree with the following statements:
                  </i>
                </strong>
              </p>
              <ul>
                <li>
                  I understand that my Healthcare Provider will be at a
                  different location than me and I will receive healthcare
                  services, including assessment, treatment, diagnosis, and
                  education, using interactive audio, video, and data
                  communications.
                </li>
                <li>
                  I understand that BetterMedics may determine that its services
                  are not appropriate for some or all of my treatment needs and
                  may elect not to provide services to me.
                </li>
                <li>
                  I attest that I have the legal authority to sign for myself or
                  to act as guardian or personal representative for all persons
                  under the age of 18 years old registered under my BetterMedics
                  Account.
                </li>
                <li>
                  I understand that BetterMedics Healthcare Providers may share
                  my health records with other healthcare providers for purposes
                  relating to the provision, coordination or management of my
                  care to meet my healthcare needs. This may include information
                  relating to clinical and diagnostic testing, communicable
                  diseases, and other health conditions, subject to the terms in
                  the BetterMedics{" "}
                  <a
                    href="https://bettermedics.org/privacy-practices"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Notice of Privacy Practices
                  </a>
                  .
                </li>
                <li>
                  I understand that individuals other than my Healthcare
                  Provider may be present during my telehealth visit in order to
                  operate the telehealth technologies. I will be informed of
                  their presence and will have the right to: (1) limit the
                  information I share; (2) ask non-clinical/unlicensed personnel
                  to leave the telehealth visit at any time; and/or (3)
                  terminate the telehealth visit at any time.
                </li>
                <li>
                  I understand that while there are potential benefits in the
                  use of telehealth, none of these benefits can be guaranteed or
                  assured.
                </li>
                <li>
                  BetterMedics has explained to me the alternatives to a
                  telehealth visit for certain conditions or treatments.
                </li>
                <li>
                  In choosing to participate in a telehealth visit, I understand
                  that some parts of the exam involving physical tests may be
                  conducted by individuals at my remote location at the
                  direction of the consulting Healthcare Provider.
                </li>
                <li>
                  I understand that I may withhold or withdraw my consent to the
                  use of telehealth at any time by notifying my BetterMedics
                  Healthcare Provider. I understand that the result of
                  withdrawing my consent is that I may no longer receive
                  services from BetterMedics.
                </li>
              </ul>
            </Block>

            <Block>
              <p>
                <strong>
                  By selecting "I agree," you hereby acknowledge that you have
                  read this document carefully and understand the risks and the
                  information provided above regarding the use of telehealth.
                  You have had the opportunity to discuss this document and the
                  information contained herein with a healthcare provider or
                  other individual of your choosing and any questions you had
                  have been answered to your satisfaction. You hereby give your
                  consent to BetterMedics to provide telehealth services to you
                  for consultation, evaluation, diagnosis, and related treatment
                  purposes under the terms described herein.
                </strong>
              </p>
            </Block>
          </Content>
        </Row>
      </Section>
    </Container>
  );
};

const Title = styled.h1`
  margin-bottom: 20px;
`;

const Date = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.textColors.tertiary};
  margin-bottom: 50px;
`;

const Container = styled.div`
  position: relative;
  max-width: ${(props) => (props.legal ? "initial" : "1200px")};
  margin: 0 auto;
  background-color: white;

  p,
  li {
    line-height: 1.8;
    font-size: 15px;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.secondary};
  }

  ol {
    margin-bottom: 40px;
  }

  li {
    color: ${(props) => props.theme.textColors.secondary};
    padding-left: 10px;
    margin-bottom: 10px;
  }

  ${({ legal }) =>
    legal &&
    `
    max-width: initial;

    section {
      padding: 40px;
    }

    ${Row} {
      max-width: initial;
      width: 100%;
    }

    ${Title} {
      font-size: 36px;
    }

    h2 {
      font-size: 26px;
    }
  `}
`;

const Section = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
`;

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  width: calc(100% - 64px);

  @media (max-width: 59.9375em) {
    margin: 0 auto;
    max-width: 500px;
  }
`;

const Content = styled.div`
  position: relative;
  flex: 0 1 auto;
`;

const Block = styled.div`
  margin: 20px 0;
  padding-bottom: 10px;

  p {
    margin-bottom: 30px;
  }

  em {
    font-style: italic;
  }

  ul {
    margin-bottom: 30px;
  }

  address {
    display: block;
    margin-top: 20px;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export default InformedConsent;
