import React from "react";
import styled from "styled-components";
import {
  FaXTwitter,
  FaFacebookF,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa6";
import Container from "./common/Container";
import Column from "./common/Column";
import Row from "./common/Row";

import logo from "../images/bettermedics-logo.svg";

const Footer = () => (
  <Wrapper>
    <Container>
      <Row valign="top" reverseMobile mobileGap={40}>
        <Column align="left" width="60%">
          <a href="/">
            <Logo src={logo} alt="BetterMedics" />
          </a>
          <Row align="left" gap={14} rowOnMobile mobileGap={20}>
            <SocialLink href="https://www.youtube.com/@bettermedics">
              <FaYoutube />
            </SocialLink>
            {/* <SocialLink href="#">
              <FaXTwitter />
            </SocialLink> */}
            <SocialLink href="https://www.instagram.com/bettermedics/">
              <FaInstagram />
            </SocialLink>
            <SocialLink href="https://www.facebook.com/BetterMedics1">
              <FaFacebookF />
            </SocialLink>
          </Row>
        </Column>
        <Column width="40%">
          <Row align="right" valign="top" rowOnMobile>
            <Column
              align="left"
              valign="top"
              width="50%"
              gap={25}
              mobileGap={20}
            >
              <span>Learn More</span>
              <a href="/patients">Patients</a>
              <a href="/caregivers">Caregivers</a>
              <a href="/resources">Resources</a>
              <a href="/faqs">FAQs</a>
            </Column>
            <Column align="left" width="50%" gap={25} mobileGap={20}>
              <span>Company</span>
              <a href="/about">About us</a>
              <a href="/partners">For partners</a>
              <a href="/careers">We're hiring!</a>
              <a href="/contact">Contact us</a>
            </Column>
          </Row>
        </Column>
      </Row>
      <Legal>
        <Row mobileAlign="flex-start" reverseMobile mobileGap={30}>
          <p>{`© ${new Date().getFullYear()} BetterMedics, Inc.`}</p>
          <AuxLinks>
            <a href="/terms">Terms of Use</a>
            <span>|</span>
            <a href="/privacy">Privacy Policy</a>
            <span>|</span>
            <a href="/privacy-practices">Privacy Practices</a>
            <span>|</span>
            <a href="/notice-of-non-discrimination">
              Notice of Non-Discrimination
            </a>
          </AuxLinks>
        </Row>
      </Legal>
    </Container>
  </Wrapper>
);

const Logo = styled.img`
  display: block;
  max-width: 200px;
  margin-bottom: 30px;
  object-fit: contain;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const Legal = styled.div`
  margin-top: 40px;
  border-top: 1px solid ${(props) => props.theme.colors.border};
  padding: 30px 0 40px;
`;

const Wrapper = styled.footer`
  padding: 60px 0 0;

  span {
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: 17px;
    font-weight: 500;
    color: ${(props) => props.theme.textColors.primary};
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.textColors.primary};

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  p {
    font-size: 13px;
    color: ${(props) => props.theme.textColors.tertiary};
    margin: 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 40px 20px 20px;
    text-align: left;

    span {
      display: none;
    }

    a {
      font-size: 15px;
      width: 100%;
    }
  }
`;

const AuxLinks = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;

  font-size: 13px;
  color: ${(props) => props.theme.textColors.tertiary};

  a {
    margin: 0 10px;
    color: ${(props) => props.theme.textColors.tertiary};

    &:hover {
      color: ${(props) => props.theme.textColors.tertiary};
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  span {
    font-size: 13px;
    color: ${(props) => props.theme.textColors.tertiary};
    opacity: 0.7;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 0;
    gap: 20px;

    a {
      margin: 0;
      font-size: 14px;
    }
  }
`;

const SocialLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 38px;
  background-color: ${(props) => props.theme.colors.border};
  cursor: pointer;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  svg {
    font-size: 17px;
    color: ${(props) => props.theme.textColors.secondary};
  }

  @media only screen and (max-width: 768px) {
    width: 44px !important;
    height: 44px;
  }
`;

export default Footer;
