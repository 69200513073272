import React from "react";
import styled from "styled-components";
import { MdCheckCircle } from "react-icons/md";
import Container from "../../common/Container";
import Column from "../../common/Column";
import Row from "../../common/Row";

import photo from "../../../images/home/medical-professionals.jpg";

const BULLETS = [
  {
    id: "licensed",
    label: "Licensed & Board Certified",
  },
  {
    id: "experienced",
    label: "30+ Years of Experience",
  },
  {
    id: "passionate",
    label: "Passionate about patient care",
  },
];

const MedicalProfessionals = () => (
  <Section id="medical-professionals">
    <Container>
      <Content>
        <Row gap={50}>
          <Column align="left" width="45%">
            <h3>
              Experienced, licensed medical professionals are here for you.
            </h3>
            <p>
              Qualified physicians you can trust to guide you through your
              health needs.
            </p>
            <div>
              {BULLETS.map((point) => (
                <Bullet key={point.id}>
                  <MdCheckCircle />
                  <span>{point.label}</span>
                </Bullet>
              ))}
            </div>
          </Column>
          <Column width="55%">
            <Image src={photo} />
          </Column>
        </Row>
      </Content>
    </Container>
  </Section>
);

const Section = styled.section`
  background: white;

  h3 {
    font-size: 40px;
    margin-bottom: 14px;
    line-height: 1.3;
  }

  p {
    font-size: 22px;
    padding-right: 40px;
    line-height: 1.5;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 768px) {
    h3 {
      font-size: 32px;
    }

    p {
      font-size: 18px;
      padding-right: 0;
    }
  }
`;

const Content = styled.div`
  padding: 120px 0;

  @media only screen and (max-width: 768px) {
    padding: 80px 0 40px;
    text-align: center;
  }
`;

const Image = styled.img`
  display: block;
  object-fit: contain;
  max-width: 100%;
`;

const Bullet = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 26px;

  svg {
    font-size: 28px;
    color: ${(props) => props.theme.textColors.primary};
  }

  span {
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    color: ${(props) => props.theme.textColors.primary};
  }

  @media only screen and (max-width: 768px) {
    gap: 10px;
    span {
      font-size: 16px;
    }
    svg {
      font-size: 24px;
    }
  }
`;

export default MedicalProfessionals;
