import React from "react";
import styled from "styled-components";

const PersonalHealthDisclosure = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const isLegal = queryParameters.get("legal");

  return (
    <Container legal={isLegal}>
      <Section>
        <Row>
          <Content>
            <Title>
              Notice of Personal and Health Information Disclosure to Caregivers
            </Title>
            <Date>Last Updated: May 24, 2023</Date>
            <Block>
              <p>
                This Notice of Personal and Health Information Disclosure to
                Caregivers (<b>"Notice"</b>) describes how BetterMedics, Inc. (
                <b>"BetterMedics"</b>, <b>"we"</b> or <b>"our"</b>) may disclose
                your personal and health information to the individual(s) that
                you designate as your caregiver(s) when you sign up with
                BetterMedics (each, a “Caregiver”). Further details regarding
                how we use and disclose your personal and health information,
                including for other purposes and to other persons and entities,
                are provided in our Privacy Policy. To the extent anything in
                this Notice conflicts with our Privacy Policy, the applicable
                provisions of the Privacy Policy will control.
              </p>
              <p>
                If you utilize a Caregiver(s) with BetterMedics, we may disclose
                your personal and health information to your Caregiver(s) in
                connection with the services we provide you. For example, we may
                disclose your exam details and treatment information to your
                Caregiver(s) to facilitate your care plan, and your personal and
                health information may be disclosed to your Caregiver(s) when
                you receive or use our telehealth services and your Caregiver(s)
                is present. The information we disclose to your Caregiver(s) may
                include sensitive health information, such as information about
                alcohol/substance abuse or mental health.
              </p>
              <p>
                If you wish to restrict a Caregiver's access to or receipt of
                certain of your personal or health information, such as any
                categories of sensitive health information, you can submit a
                request for such restriction to us in writing at the e-mail
                address provided at the bottom of this Notice. BetterMedics may
                in its sole discretion accept or reject, in whole or in part,
                any such request for restriction based on the ability to provide
                the services.
              </p>
              <p>
                BetterMedics may from time-to-time update or modify this Notice
                and the Privacy Policy.
              </p>
            </Block>
            <Block>
              <h2>Contact Us</h2>
              <p>
                If you have any questions or concerns regarding this Notice,
                contact us by email at{" "}
                <a href="mailto:privacy.officer@bettermedics.org">
                  Privacy.Officer@BetterMedics.org
                </a>
                , Attn: Privacy Officer, or by telephone at (216) 273-8400
              </p>
            </Block>
          </Content>
        </Row>
      </Section>
    </Container>
  );
};

const Title = styled.h1`
  margin-bottom: 20px;
`;

const Date = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.textColors.tertiary};
  margin-bottom: 50px;
`;

const Container = styled.div`
  position: relative;
  max-width: ${(props) => (props.legal ? "initial" : "1200px")};
  margin: 0 auto;
  background-color: white;

  p,
  li {
    line-height: 1.8;
    font-size: 15px;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.secondary};
  }

  ol {
    margin-bottom: 40px;
  }

  li {
    color: ${(props) => props.theme.textColors.secondary};
    padding-left: 10px;
    margin-bottom: 10px;
  }

  ${({ legal }) =>
    legal &&
    `
    max-width: initial;

    section {
      padding: 40px;
    }

    ${Row} {
      max-width: initial;
      width: 100%;
    }

    ${Title} {
      font-size: 36px;
    }

    h2 {
      font-size: 26px;
    }
  `}
`;

const Section = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
`;

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  width: calc(100% - 64px);

  @media (max-width: 59.9375em) {
    margin: 0 auto;
    max-width: 500px;
  }
`;

const Content = styled.div`
  position: relative;
  flex: 0 1 auto;
`;

const Block = styled.div`
  margin: 20px 0;
  padding-bottom: 10px;

  p {
    margin-bottom: 30px;
  }

  em {
    font-style: italic;
  }

  ul {
    margin-bottom: 30px;
  }

  address {
    display: block;
    margin-top: 20px;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export default PersonalHealthDisclosure;
