import React from "react";
import styled from "styled-components";

/**
 *
 * @param {String} align defaults to center, converts "left" to "flex-start" and "right" to "flex-end"
 * @param {String} valign defaults to center, converts "top" to "flex-start" and "bottom to "flex-end"
 * @param {Integer} gap space between items
 * @param {Integer} mobileGap space for mobile only
 * @returns
 */

const Column = ({
  align = "center",
  valign = "center",
  width = "100%",
  gap = 0,
  mobileGap,
  children,
}) => (
  <Wrapper
    $align={align}
    $valign={valign}
    $width={width}
    $gap={gap}
    $mobileGap={mobileGap}
  >
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.$align === "left"
      ? "flex-start"
      : props.$align === "right"
      ? "flex-end"
      : props.$align};
  justify-content: ${(props) =>
    props.$valign === "top"
      ? "flex-start"
      : props.$valign === "bottom"
      ? "flex-end"
      : props.$valign};
  width: ${(props) => props.$width};
  gap: ${(props) => `${props.$gap}px`};

  @media only screen and (max-width: 768px) {
    width: 100%;
    align-items: center;

    ${({ $mobileGap }) =>
      $mobileGap &&
      `
      gap: ${$mobileGap}px;
    `}
  }
`;

export default Column;
