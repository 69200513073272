import React from "react";
import styled from "styled-components";
import Button from "../../common/Button";
import Container from "../../common/Container";
import Column from "../../common/Column";
import Row from "../../common/Row";

import forHome from "../../../images/caregivers/for-your-home.webp";
import forLovedOnes from "../../../images/caregivers/for-your-loved-ones.webp";
import gift from "../../../images/caregivers/gift-of-companionship.webp";

const Copy = () => (
  <Section id="caregivers-copy">
    <Container>
      <Content>
        <Group>
          <Row gap={80} mobileGap={40} reverseMobile>
            <Column align="left" width="45%">
              <h3>Caregivers Living with their Loved-One</h3>
              <p>
                Your loved-one's health status is changing and they are upset.
                These moments are scary and overwhelming. You need support now.
              </p>
            </Column>
            <Column width="55%">
              <Image src={forHome} />
            </Column>
          </Row>
        </Group>
        <Group>
          <Row gap={80} mobileGap={40}>
            <Column width="55%">
              <Image src={forLovedOnes} />
            </Column>
            <Column align="left" width="45%">
              <h3>Caregivers Assisting an Independent Loved-One</h3>
              <p>
                You are there for your loved-one as much as possible. However-
                you still worry about them being on their own. Let BetterMedics
                move in.
              </p>
            </Column>
          </Row>
        </Group>
        <Group>
          <Row gap={80} mobileGap={40} reverseMobile>
            <Column align="left" width="45%">
              <h3>Give the Gift of BetterMedics</h3>
              <p>
                Healthcare Companionship is one of the most meaningful gifts you
                can give to aging parents or loved-ones who are managing chronic
                illness.
              </p>
              <Button onClick={() => window.openForm()}>
                Enroll a loved one
              </Button>
            </Column>
            <Column width="55%">
              <Image src={gift} />
            </Column>
          </Row>
        </Group>
      </Content>
    </Container>
  </Section>
);

const Group = styled.div`
  margin: 120px 0;

  &:first-of-type {
    margin-top: 0;
  }

  @media only screen and (max-width: 768px) {
    margin: 80px 0;
  }
`;

const Section = styled.section`
  background: white;

  h3 {
    font-size: 40px;
    margin-bottom: 14px;
    line-height: 1.3;
  }

  p {
    font-size: 22px;
    padding-right: 40px;
    line-height: 1.5;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 992px) {
    h3 {
      font-size: 36px;
    }
    p {
      padding-right: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    text-align: center;

    h3 {
      font-size: 32px;
    }

    p {
      font-size: 18px;
      padding-right: 0;
    }
  }
`;

const Content = styled.div`
  padding: 120px 0;

  @media only screen and (max-width: 992px) {
    padding: 80px 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 60px 0;
  }
`;

const Image = styled.img`
  display: block;
  object-fit: contain;
  max-width: 100%;
  border-radius: 80px;
  overflow: hidden;

  box-shadow: 30px 20px 0 rgba(244, 235, 222, 0.5);

  @media only screen and (max-width: 992px) {
    box-shadow: 15px 10px 0 rgba(244, 235, 222, 0.5);
    border-radius: 40px;
  }

  @media only screen and (max-width: 768px) {
    box-shadow: none;
  }
`;

export default Copy;
