import React from 'react';
import styled from 'styled-components';

const Support = () => (
  <Container centered>
    <Section>
      <Row>
        <Content>
          <Title>Technical Support</Title>
          <Block>
            <h2>Have Questions?</h2>
            <p>Our care team is standing by and ready to help with all technical related questions.</p>
            <p>To connect with our support team, email us at:<br /><a href="mailto:support@bettermedics.org">support@bettermedics.org</a></p>
          </Block>
        </Content>
      </Row>
    </Section>
  </Container>
);

const Title = styled.h1`
  margin-bottom: 90px;
`;

const Container = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  text-align: ${props => props.centered ? 'center' : 'left'};

  p, li {
    line-height: 1.8;
    font-size: 15px;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.secondary};
  }

  ol {
    margin-bottom: 40px;
  }

  li {
    color: ${props => props.theme.textColors.secondary};
    padding-left: 10px;
    margin-bottom: 10px;
  }
`;

const Section = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
`;

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  width: calc(100% - 64px);

  @media (max-width: 59.9375em) {
    margin: 0 auto;
    max-width: 500px;
  }
`;

const Content = styled.div`
  position: relative;
  flex: 1;
`;

const Block = styled.div`
  margin: 20px 0;
  padding-bottom: 10px;

  p {
    margin-bottom: 30px;
  }

  strong {
    padding-right: 10px;
  }

  ul {
    margin-bottom: 30px;
  }

  address {
    display: block;
    margin-top: 20px;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export default Support;
