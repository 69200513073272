import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import { lightFormat, isLastDayOfMonth } from "date-fns";

// Full URL example:
// https://calendly.com/bettermedics-dev/onboarding?name=Donovan%20Mitchell&email=d.mitch@cavs.com&month=2023-06&hide_gdpr_banner=1&embed_domain=1"

// URL Params can be:
// {
// 	calendarURL,  // required:  https://calendly.com/bettermedics-dev/onboarding
// 	name,
//  email,
// }

const Calendly = () => {
  const isInitialMount = useRef(true);
  const [url, setURL] = useState();
  const [isWebview, setIsWebview] = useState(false);

  const onEventScheduled = (e) => {
    if (isWebview) {
      window.ReactNativeWebView.postMessage(JSON.stringify(e.data));
    } else window.postMessage(JSON.stringify(e.data));
  };

  useCalendlyEventListener({
    onEventScheduled,
  });

  useEffect(() => {
    const onInit = () => {
      const urlParams = new URLSearchParams(window.location.search);
      let calendarURL = urlParams.get("calendarURL");

      if (!calendarURL) window.location = "/404";

      const email = urlParams.get("email");
      const webview = urlParams.get("webview");
      if (webview) setIsWebview(true);

      // last day of month will open calendar with no dates available -- advance to next month
      const currentDate = new Date();
      if (isLastDayOfMonth(new Date()))
        currentDate.setDate(currentDate.getDate() + 1);
      const month = lightFormat(currentDate, "yyyy-MM");

      if (email) calendarURL += `&email=${email}`;
      calendarURL += `&hide_gdpr_banner=1&embed_domain=1&month=${month}`;

      setURL(encodeURI(calendarURL));
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      onInit();
    }
  }, []);

  return url ? (
    <Wrapper>
      <InlineWidget url={url} styles={{ height: "100%" }} />
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export default Calendly;
