import React from "react";
import styled from "styled-components";

const Terms = () => (
  <Container>
    <Section>
      <Row>
        <Content>
          <Title>Terms of Use</Title>
          <Date>Last Updated: March 6, 2022</Date>
          <Block>
            <p>
              BetterMedics, Inc. ("<b>BetterMedics</b>," "<b>we</b>," "<b>us</b>
              ," or "<b>our</b>") owns and operates websites, software
              applications (including mobile apps), and online platforms
              (together, the "<b>Platforms</b>") through which you may access to
              or and receive telehealth and related services directly from
              healthcare providers (the "<b>Telehealth Services</b>"). These
              Terms of Use ("<b>Terms</b>") govern your use of the Platforms,
              including with respect to your receipt of the Telehealth Services.{" "}
              <b>PLEASE READ THESE TERMS CAREFULLY</b>. These Terms apply to all
              users of the Platforms.
            </p>
            <p>
              <b>
                By accessing and/or using the Platforms (including by receiving
                or providing Telehealth Services through the Platforms), you
                agree to and accept these Terms, regardless of whether or not
                you have a Platform account. Please save a copy of these Terms.
              </b>
            </p>
            <p>
              <b>
                ARBITRATION NOTICE: These Terms contain a binding arbitration
                provision. You agree that, except for certain types of disputes
                described in the arbitration provision below, all disputes
                between you and BetterMedics will be resolved by mandatory
                binding arbitration and you waive any right to participate in a
                class action lawsuit or class-wide arbitration.
              </b>
            </p>
            <p>
              <b>
                We reserve the right to modify these Terms at any time. All
                changes will be effective immediately upon posting to the
                Platforms and, by accessing or using the Platforms after changes
                are posted, you agree to those changes. Material changes will be
                prominently posted on the Platforms.
              </b>
            </p>
          </Block>
          <Block>
            <h2>I. Information Collected by BetterMedics; Privacy Policy</h2>
            <p>
              By using the Platforms or receiving Telehealth Services, you
              consent to the processing of your information, which will be
              collected by BetterMedics, consistent with our{" "}
              <a href="/privacy">Privacy Policy</a> and
              <a href="/privacy-practices">Privacy Practices</a>.
            </p>
          </Block>

          <Block>
            <h2>II. Scope of Use</h2>
            <p>
              BetterMedics facilitates your communication and care with
              healthcare providers. However, BetterMedics does not provide any
              medical advice or physician or other healthcare providers’
              services except as identified in these Terms through an affiliated
              entity. Any information or advice received from a healthcare
              provider through the Platforms comes from the provider alone and
              not from BetterMedics. Other than the personalized content your
              healthcare provider sends you in a message within the Platforms or
              the personalized advice you receive during a telehealth visit, you
              should not consider any content on the Platforms to be medical
              advice. None of the content on the Platforms represents or
              warrants that any particular medication or treatment is safe,
              appropriate, or effective for you. Without limitation,
              BetterMedics does not recommend or endorse any specific tests,
              medications, products, or procedures.
            </p>
            <p>
              Your interactions with healthcare providers through your use of
              the Platforms are not intended to take the place of your
              relationship with your regular primary care healthcare providers
              or certain specialists. All information provided by BetterMedics
              on the Platforms or through any healthcare provider is intended to
              be for general informational purposes only and is in no way
              intended to create an exclusive physician-patient relationship.
              Reliance on any information appearing on the Platforms is solely
              at your own risk. BetterMedics or any third parties who promote
              the Platforms or the Telehealth Services on its behalf or provide
              you with a link to the Platforms shall not be liable for any
              professional advice you obtain from a healthcare provider via the
              Platforms or by choosing to use the Telehealth Services. You
              acknowledge that your reliance on information provided through the
              Platforms or the Telehealth Services is solely at your own risk
              and you assume full responsibility for all risk associated
              therewith.
            </p>
          </Block>

          <Block>
            <h2>III. Permitted Use; Ownership</h2>
            <p>
              All of the content available on or through the Platforms and any
              related materials, documentation, trademarks, service marks, and
              logos is the property of BetterMedics or its licensors and is
              protected by copyright, trademark, patent, trade secret and other
              intellectual property laws. Subject to your compliance with these
              Terms and payment of any applicable fees, we grant you a limited,
              non-exclusive, non-transferrable, non-assignable,
              non-sublicensable personal license to access and use the Platforms
              and Telehealth Services.
            </p>
            <p>
              The Platforms, including their text, audio, video, graphics,
              charts, photographs, interfaces, icons, software, computer code,
              data, trademarks, logos, slogans, names of products and services,
              documentation, other components and content, and the design,
              selection, and arrangement of content are exclusively the property
              of BetterMedics or, as applicable, its suppliers and licensors,
              and are protected by copyright, trademark, and other intellectual
              property laws. The Platforms may contain references to third-party
              marks and copies of third-party copyrighted materials, which are
              the property of their respective owners. Any unauthorized use of
              any trademarks, trade dress, copyrighted materials, or any other
              intellectual property rights belonging to BetterMedics or any
              third party is prohibited and may be prosecuted to the fullest
              extent of the law.
            </p>
            <p>
              Except as expressly granted in these Terms, no licenses or other
              rights, express or implied, are granted by BetterMedics to you
              under any patent, copyright, trademark, trade secret, or other
              intellectual property right of BetterMedics and all such rights
              are reserved and retained by BetterMedics.
            </p>
          </Block>

          <Block>
            <h2>IV. Healthcare Providers</h2>
            <p>
              The healthcare providers who deliver Telehealth Services through
              the Platforms are independent professionals engaged by “Complete
              Recovery LLC, D.B.A. BetterMedics, LLC,” an entity affiliated with
              BetterMedics, Inc. BetterMedics, Inc. does not practice medicine
              or any other licensed profession, and does not interfere with the
              practice of medicine by the healthcare providers who deliver
              Telehealth Services through the Platforms. Neither BetterMedics
              nor any third parties who promote the Telehealth Services or
              provide you with a link to the Telehealth Services shall be liable
              for any professional advice you obtain from a healthcare provider
              while using the Platforms or Telehealth Services.
            </p>
          </Block>

          <Block>
            <h2>V. Use Restrictions</h2>
            <p>
              You agree not to access the Platforms or use the Telehealth
              Services in an unlawful way or for an unlawful or illegitimate
              purpose or in any manner that contravenes these Terms. You shall
              not post, use, store or transmit: (a) a message or information
              under a false name; (b) information that is unlawful, libelous,
              defamatory, obscene, fraudulent, predatory of minors, harassing,
              threatening or hateful to any person; or (c) information that
              infringes or violates any of the intellectual property rights of
              others or the privacy or publicity rights of others.
            </p>
            <p>
              You shall not attempt to disrupt the operation of the Platforms or
              the Telehealth Services by any method, including through use of
              viruses, Trojan horses, worms, time bombs, denial of service
              attacks, flooding or spamming. You shall not use the Platforms or
              the Telehealth Services in any manner that could damage, disable
              or impair the Platforms or the Telehealth Services. You shall not
              attempt to gain unauthorized access to any user accounts or
              computer systems or networks, through hacking, password mining or
              any other means. You shall not use any robot, scraper or other
              means to access the Platforms or the Telehealth Services for any
              purpose or attempt to probe, scan, or test the vulnerability of
              the Platforms or the Telehealth Services or to breach security or
              authentication measures.
            </p>
          </Block>

          <Block>
            <h2>VI. User Accounts</h2>
            <p>
              To access the Platforms and the Telehealth Services, you must
              first enroll to establish an individual user account ("Account"),
              by providing certain information. With the exception of
              subaccounts established for minor children of whom you are a
              parent or legal guardian, you agree that you will not create more
              than one Account or create an account for anyone other than
              yourself without first receiving permission from the other person.
              If you submit, upload, post or transmit any health information,
              medical history, conditions, problems, symptoms, personal
              information, consent forms, agreements, requests, comments, ideas,
              suggestions, information, files, videos, images or other materials
              to us or our platforms (“User Information”), you agree not to
              provide any User Information that (a) is false, inaccurate,
              incomplete, defamatory, abusive, libelous, unlawful, obscene,
              threatening, harassing, fraudulent, pornographic, or harmful, or
              that could encourage criminal or unethical behavior; (b) violates
              or infringes the privacy, copyright, trademark, trade dress, trade
              secrets or intellectual property rights of any person or entity;
              or (c) contains or transmits a virus or any other harmful
              component.
            </p>
            <p>
              You agree not to contact other site users through unsolicited
              e-mail, telephone calls, mailings or any other method of
              communication. You represent and warrant to BetterMedics that you
              have the legal right and authorization to provide all User
              Information to BetterMedics for use as set forth herein and
              required by BetterMedics. If you provide any User Information that
              violates the provisions of these Terms, or we have reasonable
              grounds to suspect that such User Information violates the
              provisions of these Terms, we reserve the right to suspend or
              terminate your Account and refuse any and all current or future
              use of the Platforms or the Telehealth Services. You represent and
              warrant that you possess the legal right and ability, on behalf of
              yourself or a minor child of whom you are a parent or legal
              guardian, to agree to this Agreement.
            </p>
          </Block>

          <Block>
            <h2>VII. Termination or Suspension of Account</h2>
            <p>
              BetterMedics may suspend or terminate your Account and/or your
              access to the Platforms or the Telehealth Services at any time,
              for any reason or for no reason at all. BetterMedics reserves the
              right to delete or change any username or password at any time,
              for any reason or for no reason at all.
            </p>
          </Block>

          <Block>
            <h2>VIII. Privacy and Security</h2>
            <p>
              BetterMedics has implemented a variety of commercially standard
              encryption and security technologies and procedures to protect
              your personal information which is stored in its computer systems
              from unauthorized access. We also maintain standard physical and
              electronic procedural safeguards that limit access to your
              personal information to our employees (or people working on our
              behalf and under confidentiality agreements) who, through the
              course of standard business activities, need to access your
              personal information. BetterMedics maintains all applicable PCI
              DSS requirements to the extent that it has access to, or otherwise
              stores, processes, or transmits cardholder data.
            </p>
            <p>
              Access to the Platforms and the Telehealth Services is enabled
              only by usernames and passwords. You should maintain your username
              and password in strict confidence. In no event should you share
              your username or password with any third party or allow another
              person to access your Account, or the Platforms or Telehealth
              Services using your username and password. Please notify
              BetterMedics if you have any reason to believe that your username
              or password has been lost or compromised or misused in any way.
              You are fully and solely responsible for any and all use of the
              Platforms and the Telehealth Services using your username and
              password. BetterMedics reserves the right to revoke or deactivate
              your username and password at any time and for any reason.
            </p>
            <p>
              We use your information only as described in our Privacy Policy,
              Notice of Privacy Practices, and these Terms. You can access and
              modify the information you provide us and choose not to receive
              certain communications by modifying your preferences. No method of
              transmission over the Internet, or method of electronic storage,
              is 100% secure, however. Therefore, we cannot guarantee its
              absolute security.
            </p>
          </Block>

          <Block>
            <h2>IX. Communications</h2>
            <p>
              You agree that we may send to you in electronic form any privacy
              or other notices, disclosures, reports, documents, communications,
              or other records regarding the Platforms, the Telehealth Services,
              or other information that may be applicable or of interest to you
              (collectively, “Notices”). We can send you electronic Notices as
              follows: (a) to the e-mail address that you provided to us during
              registration; or (b) by posting the Notice on the Telehealth
              Service or otherwise through our site. The delivery of any Notice
              from us is effective when sent by us, regardless of whether you
              read the Notice when you receive it or whether you actually
              receive the delivery. You can withdraw your consent to receive
              certain Notices electronically by canceling or discontinuing your
              use of the applicable service or by emailing us at{" "}
              <a href="mailto:privacy.officer@bettermedics.org">
                Privacy.Officer@BetterMedics.org
              </a>
              .
            </p>
            <p>
              You can send messages to a healthcare provider through the
              Platforms or by contacting our Member Support team at{" "}
              <a href="tel:+2162738400">(216) 273-8400</a>. Messages sent
              through the Platforms to a healthcare provider or emails or
              electronic messages sent to Member Support may not be returned
              immediately.{" "}
              <b>
                IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, YOU SHOULD CALL 911
                OR GO TO THE NEAREST EMERGENCY ROOM.
              </b>
            </p>
          </Block>

          <Block>
            <h2>X. Age Restrictions</h2>
            <p>
              You certify that you are at least 18 years of age and are
              qualified under the laws of Ohio and any and all other applicable
              jurisdictions to make medical decisions on your own behalf. You
              acknowledge that your ability to access and use the Platforms and
              the Telehealth Services is conditional upon the truthfulness of
              your certification of age. If you are an emancipated minor or a
              minor in need of confidential medical treatment, you are advised
              to seek in person care rather than a telehealth consultation.
            </p>
          </Block>

          <Block>
            <h2>XI. Geographic Restriction</h2>
            <p>
              You certify that you are physically located in Ohio and you agree
              to receive the Telehealth Services and/or only interact with a
              healthcare provider through the Platforms while you are present in
              Ohio. You acknowledge that your ability to access and use the
              Platforms and the Telehealth Services is conditioned upon the
              truthfulness of this certification and that the providers you
              access are relying upon this certification in order to interact
              with you. In the event that your certification is inaccurate, you
              agree to indemnify BetterMedics and the healthcare providers from
              whom you receive the Telehealth Services from any resulting
              damages, costs or claims.
            </p>
          </Block>

          <Block>
            <h2>XII. Career Opportunities</h2>
            <p>
              The Platforms may post career opportunities and include
              instructions on how to apply for those positions. You will not
              provide to us a resume or any other job application materials for
              any person other than yourself. Your submission of a resume or
              other job application materials does not in any way require
              BetterMedics to review those materials or consider you for
              employment. Career opportunity descriptions on the Platforms are
              subject to change at our sole discretion without notice.
            </p>
          </Block>

          <Block>
            <h2>XIII. No Warranties</h2>
            <p>
              YOUR USE OF THE PLATFORMS AND THE TELEHEALTH SERVICES IS AT YOUR
              OWN RISK AND YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE
              OF THE PLATFORMS, TELEHEALTH SERVICES, AND RELATED PRODUCTS OR
              WEBSITES. THE PLATFORMS AND THE TELEHEALTH SERVICES ARE PROVIDED
              “AS IS” WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
              INCLUDING WITHOUT LIMITATION, WARRANTIES OF TITLE,
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              NON-INFRINGEMENT, OR OTHER VIOLATION OF RIGHTS.
            </p>
            <p>
              WE DO NOT WARRANT THE ADEQUACY, CURRENCY, ACCURACY, LIKELY
              RESULTS, OR COMPLETENESS OF THE TELEHEALTH SERVICES OR ANY
              THIRD-PARTY SITES LINKED TO OR FROM THE PLATFORMS OR THE
              TELEHEALTH SERVICES, OR THAT THE FUNCTIONS PROVIDED WILL BE
              UNINTERRUPTED, VIRUS-FREE, OR ERROR-FREE. WE EXPRESSLY DISCLAIM
              ANY LIABILITY FOR ANY ERRORS OR OMISSIONS IN THE CONTENT INCLUDED
              IN THE PLATFORMS OR THE TELEHEALTH SERVICES OR ANY THIRD-PARTY
              SITES LIKED TO OR FROM THE PLATFORMS OR THE TELEHEALTH SERVICES.
              WITHOUT LIMITING THE FOREGOING, BETTERMEDICS DOES NOT WARRANT THAT
              ANY EXISTING DEFECT ON THE PLATFORMS OR RELATING TO THE TELEHEALTH
              SERVICES WILL BE CORRECTED.
            </p>
            <p>
              YOU ACKNOWLEDGE AND AGREE THAT THE PLATFORMS AND ACCESS THERETO
              MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT
              IN THE USE OF THE INTERNET (INCLUDING YOUR INTERNET CONNECTION),
              WIRELESS NETWORKS, TELECOMMUNICATIONS, AND ELECTRONIC
              COMMUNICATIONS. BETTERMEDICS IS NOT RESPONSIBLE FOR ANY DELAYS,
              INACCURACIES, DELIVERY FAILURES, OR OTHER FAILURES OR DAMAGE
              RESULTING FROM THOSE PROBLEMS OR ANY OTHER PROBLEMS OUTSIDE OF
              BETTERMEDICS’ REASONABLE AND DIRECT CONTROL, INCLUDING WITHOUT
              LIMITATION TELECOMMUNICATIONS SERVICES, THE INTERNET, YOUR
              INTERNET CONNECTION, WIRELESS NETWORKS, AND ANY THIRD-PARTY
              SOFTWARE OR SERVICES/CONTENT.
            </p>
            <p>
              NO ADVICE OR INFORMATION, ORAL OR WRITTEN, OBTAINED BY YOU FROM
              BETTERMEDICS OR IN ANY MANNER FROM THE TELEHEALTH SERVICES CREATE
              ANY WARRANTY.
            </p>
            <p>
              SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF IMPLIED
              WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
            </p>
          </Block>
          <Block>
            <h2>XIV. Limitation of Liability</h2>
            <p>
              NEITHER BETTERMEDICS, NOR ANY OF ITS SUBSIDIARIES OR AFFILIATES OR
              ANY THIRD PARTY WHO MAY PROMOTE THE PLATFORMS OR THE TELEHEALTH
              SERVICES OR PROVIDE A LINK TO THE PLATFORMS OR THE TELEHEALTH
              SERVICES, NOR ANY OF THEIR RESPECTIVE DIRECTORS, OFFICERS,
              EMPLOYEES, REPRESENTATIVES, SUCCESSORS, OR ASSIGNS, SHALL BE
              LIABLE FOR ANY PROFESSIONAL ADVICE OBTAINED FROM A HEALTHCARE
              PROVIDER VIA THE PLATFORMS OR THE TELEHEALTH SERVICES OR FOR ANY
              OTHER INFORMATION OBTAINED ON THE PLATFORMS.
            </p>
            <p>
              BETTERMEDICS DOES NOT ENDORSE ANY SPECIFIC TESTS, PHYSICIANS,
              HEALTHCARE PROVIDERS, MEDICATIONS, PRODUCTS, OR PROCEDURES THAT
              ARE RECOMMENDED BY PROVIDERS THAT MAY USE THE PLATFORMS TO
              COMMUNICATE WITH YOU. YOU ACKNOWLEDGE THAT YOUR RELIANCE ON ANY
              HEALTHCARE PROVIDERS OR INFORMATION CONTAINED IN THE PLATFORMS OR
              PROVIDED BY THE HEALTHCARE PROVIDERS VIA THE PLATFORMS OR THROUGH
              THE TELEHEALTH SERVICES IS SOLELY AT YOUR OWN RISK AND YOU ASSUME
              FULL RESPONSIBILITY FOR ALL RISKS ASSOCIATED HEREWITH.
            </p>
            <p>
              BETTERMEDICS CANNOT AND DOES NOT ASSUME ANY RESPONSIBILITY FOR ANY
              LOSS, DAMAGES, OR LIABILITIES ARISING FROM THE FAILURE OF ANY
              TELECOMMUNICATIONS INFRASTRUCTURE OR THE INTERNET OR FOR YOUR
              MISUSE OF ANY PROTECTED HEALTH INFORMATION, ADVICE, IDEAS,
              INFORMATION, INSTRUCTIONS, OR GUIDELINES ACCESSED THROUGH THE
              PLATFORMS OR THE TELEHEALTH SERVICES.
            </p>
            <p>
              IN NO EVENT WILL WE, OR OUR PARENTS, SUBSIDIARIES, AFFILIATES,
              LICENSORS, SUPPLIERS AND THEIR DIRECTORS, OFFICERS, AFFILIATES,
              SUBCONTRACTORS, EMPLOYEES, AGENTS, AND ASSIGNS BE LIABLE FOR ANY
              DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
              PUNITIVE DAMAGES, LOST PROFITS, OR OTHER DAMAGES WHATSOEVER
              ARISING FROM OR RELATING IN ANY WAY TO THE USE OF THE PLATFORMS OR
              THE TELEHEALTH SERVICES, ANY INTERRUPTION IN AVAILABILITY OF THE
              PLATFORMS OR THE TELEHEALTH SERVICES, DELAY IN OPERATION OR
              TRANSMISSION, COMPUTER VIRUS, LOSS OF DATA, OR USE, MISUSE,
              RELIANCE, REVIEW, MANIPULATION, OR OTHER UTILIZATION IN ANY MANNER
              WHATSOEVER OF THE PLATFORMS OR THE TELEHEALTH SERVICES OR THE DATA
              COLLECTED THROUGH THE PLATFORMS OR THE TELEHEALTH SERVICES, EVEN
              IF ONE OR MORE OF THEM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES OR LOSS.
            </p>
            <p>
              YOU AGREE THAT YOUR SOLE REMEDY FOR YOUR DISSATISFACTION WITH THE
              PLATFORMS OR THE TELEHEALTH SERVICES OR FOR ANY CLAIM ARISING FROM
              OR RELATING IN ANY WAY TO THE PLATFORMS OR THE TELEHEALTH SERVICES
              WILL BE TO CEASE USING THE PLATFORMS OR THE TELEHEALTH SERVICES.
              WITHOUT LIMITING THE FOREGOING, IN NO EVENT WILL BETTERMEDICS’
              LIABILITY TO YOU EXCEED U.S.D. $50, EVEN IF THIS REMEDY FAILS OF
              ITS ESSENTIAL PURPOSE.
            </p>
            <p>
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
              CERTAIN DAMAGES, SO SOME OF THE ABOVE EXCLUSIONS AND LIMITATIONS
              MAY NOT APPLY TO YOU. IN SUCH STATES OR JURISDICTIONS OUR
              LIABILITY SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY
              APPLICABLE LAW.
            </p>
          </Block>
          <Block>
            <h2>XV. Indemnification</h2>
            <p>
              You agree to indemnify, hold harmless, and defend BetterMedics,
              and its parents, subsidiaries, affiliates, licensors, suppliers
              and their officers, directors, affiliates, subcontractors, agents
              and employees (collectively, “Indemnified Parties” and each,
              individually, an “Indemnified Party”) against all claims, demands,
              obligations, losses, costs, expenses, liabilities and damages
              (including reasonable attorney’s fees) incurred by any Indemnified
              Party in connection with any claims arising out of or related to:
              (a) your use of or access to the Platforms and the services
              provided in connection with the Platforms; (b) your use of or
              receipt of the Telehealth Services; (c) your failure to comply
              with any applicable laws and regulations; (d) your breach of these
              Terms; (e) any claim that your content or action caused damage to
              a third party; or (f) your negligence, willful misconduct, or
              fraud.
            </p>
          </Block>
          <Block>
            <h2>
              XVI. Right to Modify or Change the Platforms or Telehealth
              Services
            </h2>
            <p>
              BetterMedics may, among other things withdraw, suspend or
              discontinue all or part of the Platforms or the Telehealth
              Services, including any functionality or feature of the Platforms
              or the Telehealth Services, temporarily or permanently, without
              prior notice. Subject to applicable law and our Privacy Policy,
              BetterMedics reserves the right to maintain, delete or destroy all
              communications, data, and materials posted or uploaded to the
              Platforms or through the Telehealth Services pursuant to its
              internal record retention and/or destruction policies.
            </p>
          </Block>
          <Block>
            <h2>XVII. Third-Party Websites and Services</h2>
            <p>
              The Platforms may link to, or be linked to, websites and services
              not maintained or controlled by BetterMedics. Those links are
              provided as a convenience and BetterMedics is not responsible for
              examining or evaluating the content or accuracy of, and does not
              warrant or endorse, any third-party website or services or any
              products or services made available through those websites or
              services. Please take care when leaving the Platforms to visit a
              third-party website or service. You should read the terms of use
              and privacy policy for each website and service that you use. You
              agree that we will not be responsible for any loss or damage of
              any sort incurred as a result of any such links or as the result
              of the presence of such links on our Platforms.{" "}
            </p>
          </Block>
          <Block>
            <h2>XVIII. Relationship of the Parties</h2>
            <p>
              You and BetterMedics are independent contractors, and nothing in
              these Terms will create any partnership, joint venture, agency,
              franchise, sales representative relationship, or employment
              relationship between you and us or our respective affiliates. You
              will have no authority to make or accept any offers or
              representation on our or our affiliates’ behalf. You agree not to
              make any statement, whether orally, on your website or its
              subdomains, or otherwise, that contradicts or may contradict
              anything in this section.
            </p>
          </Block>
          <Block>
            <h2>XIX. Waiver</h2>
            <p>
              Any waiver by BetterMedics of a breach of any provision of these
              Terms shall not operate as or be construed to be a waiver of any
              other breach of such provision or of any breach of any other
              provision of these Terms. Any waiver must be in writing. Failure
              by BetterMedics to insist upon strict adherence to any term of
              these Terms on one or more occasions shall not be considered a
              waiver or deprive BetterMedics of the right to insist upon strict
              adherence to that term or any other term of these Terms.
            </p>
          </Block>
          <Block>
            <h2>XX. Severability</h2>
            <p>
              If any provision of these Terms is held unenforceable or invalid
              under any applicable law or is so held by applicable court
              decision, such unenforceability or invalidity will not render
              these Terms unenforceable or invalid as a whole, and such
              provision will be changed and interpreted so as to best accomplish
              the objectives of such unenforceable or invalid provision within
              the limits of applicable law or the applicable court decisions.
            </p>
          </Block>
          <Block>
            <h2>XXI. Force Majeure</h2>
            <p>
              BetterMedics will not be liable or responsible to you or deemed to
              be in breach of these Terms if our action or omission to act is
              due to any event or occurrence beyond BetterMedics’ reasonable
              control, including, without limitation: (a) acts of God; (b)
              flood, fire, earthquake or explosion; (c) war, invasion,
              hostilities (whether war is declared or not), terrorist threats or
              acts, riot or other civil unrest; (d) government order or law; (e)
              actions, embargoes or blockades in effect on or after the date of
              these Terms; (f) action by any governmental authority; (g)
              governmental health restrictions or advisories; (h) disease,
              epidemics or pandemics; (i) national or regional emergency; (j)
              electrical shortage or failure of public networks; or (k) any
              other similar events or circumstances.
            </p>
          </Block>
          <Block>
            <h2>
              XXII. Governing Law; Dispute Resolution; Jury and Class Waiver
            </h2>
            <p>
              These Terms and your access and use of the Platforms and the
              Telehealth Services shall be governed by and interpreted in
              accordance with the laws of the State of Ohio without giving
              effect to the principles of conflict of laws.{" "}
              <b>
                PLEASE READ THE FOLLOWING PARAGRAPHS CAREFULLY BECAUSE THEY
                REQUIRE YOU TO ARBITRATE DISPUTES WITH BETTERMEDICS AND LIMIT
                THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.
              </b>
            </p>
            <p>
              Any dispute arising under or relating in any way to these Terms
              will be resolved exclusively by final and binding arbitration in
              Cleveland, Ohio, under the rules of JAMS/Endispute (“JAMS”) and
              the Federal Arbitration Act, 9 U.S.C. §§ 1-16, will govern the
              interpretation, enforcement, and proceedings, except that
              BetterMedics may bring a claim related to intellectual property
              rights, or seek temporary and preliminary specific performance and
              injunctive relief, in any court of competent jurisdiction, without
              the posting of bond or other security. You agree to the personal
              and subject matter jurisdiction and venue of the courts located in
              Cuyahoga County, Ohio, for any action related to these Terms.
            </p>
            <p>
              Arbitration proceedings will be administered by JAMS before an
              arbitrator selected pursuant to the JAMS rules. The decision of
              the arbitrator will be final and binding. Any final award or
              judgment may be filed and enforced in any court of competent
              jurisdiction. Each party will bear its own expenses and fees
              (including its own attorneys’ fees and costs) arising from any
              arbitration unless the arbitrator determines that your claims were
              improper or frivolous, in which case the arbitrator may require
              you to reimburse BetterMedics for certain fees and expenses in
              accordance with the JAMS rules.
            </p>
            <p>
              Any arbitration proceeding cannot be consolidated or joined with
              any other proceeding and will not proceed as a class action. Any
              and all actions taken under these Terms, including all filings,
              orders, judgments, and awards made in any arbitration proceeding,
              are confidential and must not be disclosed to any third party.
              Arbitration proceedings must be initiated within one (1) year
              after any dispute arises; otherwise, the dispute is permanently
              barred.
            </p>
          </Block>
          <Block>
            <h2>XXIII. General</h2>
            <p>
              These Terms, including our Privacy Policy and Notice of Privacy
              Practices, are the entire agreement between you and BetterMedics
              with respect to your access to and use of the Platforms. Your
              access to or use of certain features or components of the
              Platforms or the Telehealth Services may be subject to additional
              terms, conditions, rules, or policies. All such additional terms
              are incorporated by reference into these Terms.
            </p>
            <p>
              The headings in these Terms are for convenience only and will not
              affect the construction or interpretation of these Terms.
            </p>
            <p>
              These Terms do not confer any rights, remedies, or benefits upon
              any person other than you and BetterMedics, except that our
              affiliates are third-party beneficiaries of these Terms.
            </p>
            <p>
              We may assign our rights and delegate our duties under these Terms
              at any time to any party without notice to you. You will not
              assign these Terms without our prior written consent. Any
              attempted assignment by you without our prior written consent will
              be void. Subject to the foregoing, these Terms are binding upon
              and inure to the benefit of the parties’ respective successors and
              assigns.
            </p>
            <p>
              BetterMedics welcomes comments regarding the Platforms. If you
              submit comments or feedback regarding the Platforms, they will not
              be considered or treated as confidential. Excluding any components
              consisting of personal information, and except as may be
              prohibited by applicable law, we may use any comments and feedback
              that you send us in our discretion and without attribution or
              compensation to you.
            </p>
            <p>
              Any provisions of these Terms that are intended to survive
              termination (including any provisions regarding indemnification,
              limitation of our liability, or dispute resolution) will continue
              in effect beyond any termination of these Terms or of your access
              to or use of the Platforms.
            </p>
          </Block>
          <Block>
            <h2>XXIV. Contact Us</h2>
            <p>
              If you have any questions or concerns regarding these Terms,
              contact us by email at{" "}
              <a href="mailto:privacy.officer@bettermedics.org">
                Privacy.Officer@BetterMedics.org
              </a>
              , by telephone at <a href="tel:+2162738400">(216) 273-8400</a> or
              by mail at:{" "}
              <address>
                BetterMedics, Inc.
                <br />
                Attn: Privacy Officer
                <br />
                13001 Cedar Road
                <br />
                Cleveland Heights, Ohio 44118
              </address>
            </p>
          </Block>
        </Content>
      </Row>
    </Section>
  </Container>
);

const Title = styled.h1`
  margin-bottom: 20px;
`;

const Date = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.textColors.tertiary};
  margin-bottom: 50px;
`;

const Container = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;

  p,
  li {
    line-height: 1.8;
    font-size: 15px;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.secondary};
  }

  ol {
    margin-bottom: 40px;
  }

  li {
    color: ${(props) => props.theme.textColors.secondary};
    padding-left: 10px;
    margin-bottom: 10px;
  }
`;

const Section = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
`;

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  width: calc(100% - 64px);

  @media (max-width: 59.9375em) {
    margin: 0 auto;
    max-width: 500px;
  }
`;

const Content = styled.div`
  position: relative;
  flex: 0 1 auto;
  padding-bottom: 100px;
`;

const Block = styled.div`
  margin: 20px 0;
  padding-bottom: 10px;

  h2 {
    text-transform: uppercase;
    font-size: 20px;
  }

  p {
    margin-bottom: 30px;
  }

  strong {
    padding-right: 10px;
  }

  ul {
    margin-bottom: 30px;
  }

  address {
    display: block;
    margin-top: 20px;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export default Terms;
