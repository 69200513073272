export const QUESTIONS = {
  whatIsBetterMedics: {
    question: "What is BetterMedics?",
    answer:
      "<p>We're more than telehealth. We're a new layer of care in the home that provides healthcare companionship and clinical support 24 hours a day.</p>",
  },
  whoWillBeCaringForMe: {
    question: "Who will be caring for me?",
    answer:
      "<p>BetterMedics physicians are board certified, licensed professionals who specialize in family medicine and internal medicine. Each of our Physicians approach patient care with empathy and compassion. They are also passionate about taking care of geriatric and high-needs patients.</p>",
  },
  isBetterMedicsRightForMe: {
    question: "Is BetterMedics right for me?",
    answer:
      "<p>BetterMedics may be right for you if you're looking to improve the quality of your life. Our hours of operation and access to real-time data mean that you have better care- 24 hours a day.</p><p>BetterMedics is not the right solution for you if any of the following is true:</p><ul><li>You are a minor or you are under the care of a legal guardian</li><li>You are in an urgent crisis or an emergency situation</li><li>You do not have a device that can connect to the Internet or you do not have a reliable Internet connection</li></ul>",
  },
  howMuchDoesItCost: {
    question: "How much does it cost?",
    answer: "<p>BetterMedics is a flat rate per person/per month: $199.</p>",
  },
  isBetterMedicsASubstituteForPrimaryCare: {
    question: "Is BetterMedics a substitute for Primary Care?",
    answer:
      "<p>We are not a replacement for primary care or specialists. Our physicians are skilled at providing just-in-time intervention through comprehensive virtual exams.</p><p>Need to speak to a Physician at 3AM? We've got you covered. Need a prescription refill? If it's not a controlled substance- absolutely! Having a difficult situation in caring for a loved-one? We've got your back.</p>",
  },
  howIsCareProvided: {
    question: "How is care provided?",
    answer:
      "<p>Care is provided through the BetterMedics app on iOS devices (iPhone/iPad). Once you make a call you are connected within minutes to our CareLine Operators, who begin your intake and vitals collection process.  You will then be connected to a BetterMedics physician for a comprehensive virtual medical examination.</p>",
  },
  canIBeReimbursedByMyInsurance: {
    question: "Can I be reimbursed by my insurance?",
    answer:
      "<p>Neither BetterMedics nor its Physicians submit claims to Medicare, Medicaid or health insurers. Although telehealth may be eligible for reimbursement with a flexible spending account (FSA), health savings account (HSA)*, health reimbursement arrangement (HRA), and limited purpose flexible spending account (LPFSA- dental, vision, and preventive services only), <b>you will need to check with your administrator regarding reimbursement.</b></p><p>We are currently exploring partnership with payers, so check back!</p>",
  },
  howIsMyPrivacyAndSecurityProtected: {
    question: "How is my privacy and security protected?",
    answer:
      "<p>The information that you share is protected by data privacy laws, for more information see our <a href='/privacy'>Privacy Policy</a>. Here are some things that might be helpful to know:</p><ul><li>We have built state-of-the-art technology, operations, and infrastructure with the goal of protecting your privacy and safeguarding the information you provide</li><li>All the messages between you and your therapist are secured and encrypted by banking-grade 256-bit encryption.</li><li>Our servers are distributed across multiple Tier 4- AWS Data Centers for optimal security and protection.</li><li>Our browsing encryption system (SSL) follows modern best practices.</li><li>Our databases are encrypted and scrambled so they essentially become useless in the unlikely event that they are stolen or compromised.</li><li>We don't work with any insurance companies so nothing needs to be shared, reported or filed with them.</li></ul><p>If you wish for any of your information or records to be released to a third party, please let your physician know so that they can facilitate the release.</p>",
  },
  howWillIPayForMyBetterMedicsMembership: {
    question: "How will I pay for my BetterMedics membership?",
    answer:
      "<p>Your membership payments will be charged to a credit card on a recurring basis. You can cancel the subscription anytime.</p>",
  },
};
