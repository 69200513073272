import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  backgroundColors: {
    primary: "white",
    primaryRGB: "255,255,255",
    secondary: "#EAE8E4",
    secondaryRGB: "234,232,228",
    tertiary: "#F5F7FF",
    lightAlt: "#F6F8FA",
    lightAltRGB: "246,248,250",
    gray: "#EBEFF0",
    beige: "#FFF8F0",
    stone: "#F4EBDE",
    dark: "#222b37",
    darker: "#191919",
    green: "#E0F0E4",
  },
  colors: {
    primary: "#F05672",
    primaryLight: "#FCAAB6",
    primaryDark: "#319CE2",
    secondary: "#EF1D96",
    secondaryLight: "#38D2E2",
    tertiary: "#FFAA76",
    tertiaryRGB: "255,170,118",
    accent: "#FC7569",
    border: "#EAE8E4",
    borderRGB: "231,229,224",
    icon: "#2F2F2F",
    green: "#75C282",
    yellow: "#FCD47D",
    blue: "#57B3FA",
    blueRGB: "87,179,250",
    blueLight: "#91D4F2",
    pink: "#F28C9C",
    purple: "#726AB9",
    gray: "#555",
    grayLight: "#E8ECED",
    warning: "#FF6152",
    tableHover: "#FAFCFF",
    shadowRGB: "226, 228, 240",
  },
  textColors: {
    primary: "#1F3B7B",
    primaryRGB: "31,59,123",
    primaryDark: "#484B5C",
    secondary: "#5B709E",
    secondaryRGB: "91,112,158",
    tertiary: "#9A9FAE",
    tertiaryRGB: "152,159,175",
  },
  fonts: {
    primary: "'aesthet-nova', serif",
    secondary: "'Roboto', sans-serif",
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
