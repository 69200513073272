import React from "react";
import styled from "styled-components";
import Button from "../../common/Button";
import Container from "../../common/Container";
import Column from "../../common/Column";
import Row from "../../common/Row";

import devices from "../../../images/home/devices.jpg";
import wave from "../../../images/home/main-gray-wave.png";

const MedicalKit = () => (
  <Section id="medical-kit">
    <Container>
      <Content>
        <Row gap={80} mobileGap={40}>
          <Column width="45%">
            <ImageWrapper>
              <Image src={devices} alt="Medical Devices" />
            </ImageWrapper>
          </Column>
          <Column align="left" width="55%">
            <h3>Explore your Medical Kit</h3>
            <p>
              As part of your subscription, BetterMedics provides patients with
              a medical kit. These devices are used to take your vitals so our
              Physicians can assess your health status in real time.
            </p>
            <Button size="small" mobileSize="medium" href="/resources">
              Learn more
            </Button>
          </Column>
        </Row>
      </Content>
    </Container>
  </Section>
);

const Section = styled.section`
  background: white;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 235px;
    background-image: url(${wave});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    pointer-events: none;
  }

  h3 {
    font-size: 40px;
    margin-bottom: 14px;
    line-height: 1.3;
  }

  p {
    font-size: 18px;
    padding-right: 40px;
    line-height: 1.5;
    margin-bottom: 30px;
    max-width: 500px;
  }

  @media only screen and (max-width: 768px) {
    text-align: center;

    h3 {
      font-size: 32px;
    }
    p {
      font-size: 17px;
      padding-right: 0;
    }
  }
`;

const Content = styled.div`
  padding: 60px 0 180px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  border: 2px solid rgba(${(props) => props.theme.colors.borderRGB}, 0.4);
  border-radius: 8px;
  overflow: hidden;
`;

const Image = styled.img`
  display: block;
  object-fit: contain;
  max-width: 100%;
`;

export default MedicalKit;
