import React from "react";
import styled from "styled-components";
import Hero from "../components/sections/resources/Hero";
import VideoSection from "../components/sections/resources/VideoSection";
import FAQs from "../components/sections/resources/FAQs";

import pulseOx from "../images/resources/pulseox-cover.jpg";
import thermometer from "../images/resources/thermometer-cover.jpg";
import bloodPressure from "../images/resources/bp-cover.jpg";

const VIDEOS = [
  {
    id: "thermometer",
    title: "Thermometer",
    description: null,
    cover: thermometer,
    videoID: "Y-OlGteAA10",
  },
  {
    id: "pulseox",
    title: "Pulse Oximeter",
    description: null,
    cover: pulseOx,
    videoID: "90AK-tlL7aw",
  },
  {
    id: "bp",
    title: "Blood Pressure Monitor",
    description: null,
    cover: bloodPressure,
    videoID: "yBrPnQGLdO4",
  },
];

const Resources = () => (
  <Page id="resources">
    <Hero />
    <Videos>
      {VIDEOS.map((device) => (
        <VideoSection
          key={device.id}
          title={device.title}
          description={device.description}
          cover={device.cover}
          videoID={device.videoID}
        />
      ))}
    </Videos>
    <FAQs />
  </Page>
);

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const Videos = styled.div`
  padding-bottom: 80px;

  @media only screen and (max-width: 768px) {
    padding-bottom: 40px;
  }
`;

export default Resources;
