import React from "react";
import styled from "styled-components";

const Button = ({
  href,
  target,
  disabled,
  btnTheme = "primaryGradient",
  size = "medium",
  mobileSize,
  mobileExpand,
  styles,
  onClick,
  rel,
  download,
  children,
}) => (
  <Btn
    href={href ?? null}
    target={target ?? null}
    disabled={disabled}
    onClick={onClick}
    style={styles}
    rel={rel}
    download={download ?? null}
    $btnTheme={btnTheme}
    $size={size}
    $mobileSize={mobileSize}
    $mobileExpand={mobileExpand}
  >
    {children}
  </Btn>
);

const Btn = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: 0;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: bold;
  letter-spacing: 0.25px;
  text-decoration: none;
  gap: 5px;

  height: ${(props) =>
    props.$size === "medium"
      ? "44px"
      : props.$size === "large"
      ? "58px"
      : "36px"};
  padding: ${(props) =>
    props.$size === "medium"
      ? "0 24px"
      : props.$size === "large"
      ? "0 36px"
      : "0 20px"};
  border-radius: ${(props) =>
    props.$size === "medium"
      ? "44px"
      : props.$size === "large"
      ? "58px"
      : "36px"};
  font-size: ${(props) =>
    props.$size === "medium"
      ? "18px"
      : props.$size === "large"
      ? "24px"
      : "14px"};
  transition: all 0.2s ease-in;

  ${({ $btnTheme, theme }) =>
    $btnTheme === "primaryGradient" &&
    `
    background: ${theme.colors.primary};
    background: linear-gradient(120deg, ${theme.colors.primary}, ${theme.colors.tertiary});
    color: white;
  `}

  ${({ $btnTheme, theme }) =>
    $btnTheme === "primaryText" &&
    `
    background: ${theme.textColors.primary};
    color: white;
  `}

  ${({ $btnTheme, theme }) =>
    $btnTheme === "techcrunch" &&
    `
    background: white;
    color: #1A1A1A;
  `}

  ${({ disabled, theme }) =>
    disabled &&
    `
		background-color: rgba(255, 255, 255, 0.9);
		color: ${theme.textColors.secondary};
		opacity: 0.6;
    border: 2px solid ${theme.colors.border};
    pointer-events: none;
	`}

  @media only screen and (max-width: 768px) {
    ${({ $mobileSize }) =>
      $mobileSize &&
      `
      height: ${
        $mobileSize === "medium"
          ? "44px"
          : $mobileSize === "large"
          ? "58px"
          : "36px"
      };
      padding: ${
        $mobileSize === "medium"
          ? "0 24px"
          : $mobileSize === "large"
          ? "0 36px"
          : "0 20px"
      };
      border-radius: ${
        $mobileSize === "medium"
          ? "44px"
          : $mobileSize === "large"
          ? "58px"
          : "36px"
      };
      font-size: ${
        $mobileSize === "medium"
          ? "18px"
          : $mobileSize === "large"
          ? "24px"
          : "14px"
      };
    `}

    ${({ $mobileExpand }) =>
      $mobileExpand &&
      `
      width: 100%;
      box-sizing: border-box;
    `}
  }
`;

export default Button;
