import React from "react";
import styled from "styled-components";

const PrivacyPractices = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const isLegal = queryParameters.get("legal");

  return (
    <Container legal={isLegal}>
      <Section>
        <Row>
          <Content>
            <Title>Notice of Privacy Practices</Title>
            <Date>Effective Date: December 30, 2021</Date>
            <Block>
              <p>
                <b>
                  THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE
                  USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS
                  INFORMATION. PLEASE REVIEW IT CAREFULLY.
                </b>
              </p>
              <p>
                BetterMedics, LLC (<b>“BetterMedics," “we," “us,"</b> or{" "}
                <b>“our"</b>) recognizes the importance of protecting the
                privacy of your personal information, including individually
                identifiable protected health information (
                <b>“Health Information"</b>) that you provide to us for purposes
                of obtaining medical care. This Notice of Privacy Practices (
                <b>“Notice"</b>) provides you with important information about
                the privacy practices required by the Health Insurance
                Portability and Accountability Act of 1996 and all subsequent
                amendments thereto (<b>“HIPAA"</b>) as applicable to
                BetterMedics through the BetterMedics website or application
                (the <b>“Services"</b>).
              </p>
            </Block>
            <Block>
              <h2>I. OUR USE AND DISCLOSURE OF YOUR HEALTH INFORMATION</h2>
              <p>
                <u>
                  We generally use your Health Information and other information
                  we collect online in the following ways:
                </u>
              </p>
              <ul>
                <li>
                  <b>For Treatment.</b> We may use or disclose your Health
                  Information to facilitate medical treatment or services by
                  healthcare providers. We may disclose medical information
                  about you to providers, including but not limited to doctors,
                  nurses, technicians, or other personnel who are involved in
                  your care. For example, your Health Information may be
                  provided to a physician or other healthcare provider for
                  purposes of a telehealth visit or to whom you have been
                  referred for additional care.
                </li>
                <li>
                  <b>For Payment Purposes.</b> We may use or disclose your
                  Health Information to bill for or obtain payment from you, an
                  insurance company, or a third party for Services that you
                  receive from us. For example, we may contact your health
                  insurer to certify that you are eligible for benefits and may
                  provide your insurer with information about Services that you
                  receive in order to receive reimbursement for those Services.
                  We may also tell your health plan about a treatment you are
                  going to receive in order to obtain prior approval or to
                  determine whether your plan will cover the treatment.
                </li>
                <li>
                  <b>Health Care Operations.</b> We may use or disclose your
                  Health Information to perform functions necessary for the
                  operation of BetterMedics. For example, we may use or disclose
                  your Health Information to review our provision of treatment
                  and Services or to evaluate the performance of our healthcare
                  providers in caring for you. We may also share your Health
                  Information with third parties that perform various activities
                  for BetterMedics, such as billing services, administrative
                  services, payment processing, customer service, e-mail
                  deployment, and business analytics.
                </li>
                <li>
                  <b>
                    Individuals Involved in Your Care or Payment for Your Care.
                  </b>{" "}
                  We may disclose your Health Information to your family or
                  friends or any other individual identified by you when they
                  are involved in your care or in the payment for your care.
                  Additionally, we may disclose information about you to a
                  patient representative. If a person has the authority by law
                  to make health care decisions for you, we will treat that
                  patient representative the same way we would treat you with
                  respect to your Health Information.
                </li>
              </ul>
              <p>
                <u>
                  We may also use your Health Information and the information we
                  collect to do the following:
                </u>
              </p>
              <ul>
                <li>
                  <b>
                    Health-Related Benefits, Services, and Treatment
                    Alternatives.
                  </b>{" "}
                  We may use and disclose your Health Information to tell you
                  about or recommend possible treatment options or alternatives,
                  additional clinical services, products, or general wellness
                  topics that may be of interest to you.
                </li>
                <li>
                  <b>As Required By Law.</b> We may use or disclose your Health
                  Information to the extent required by federal, state, or local
                  law. For example, we may disclose your Health Information when
                  required by national security laws or public health disclosure
                  laws.
                </li>
                <li>
                  <b>Legal Purposes.</b> We may disclose your Health Information
                  in response to a court or administrative order, to carry out
                  legal processes, to respond to subpoenas, law enforcement
                  requests, legal claims or government inquiries, or to protect
                  and defend against a serious and imminent threat to the health
                  or safety of a person or the public.
                </li>
                <li>
                  <b>Certain Government Agencies and Officials.</b> We may
                  disclose your Health Information to: (i) government agencies
                  involved in oversight of the health care system; (ii)
                  government authorities authorized to receive reports of abuse,
                  neglect or domestic violence; (iii) law enforcement officials
                  for law enforcement purposes, (iv) military command
                  authorities, if you are or were a member of the armed forces;
                  (v) correctional institutions, if you are an inmate or under
                  the custody of a law enforcement official; and (vi) federal
                  officials for national security activities.
                </li>
                <li>
                  <b>
                    Public Health, Safety, Research, and Other Medical
                    Activities.
                  </b>{" "}
                  We may also disclose your Health Information: (i) for public
                  health activities or to prevent a serious threat to health and
                  safety; (ii) to organizations that handle organ and tissue
                  donations, if you are an organ donor; (iii) to coroners,
                  medical examiners and funeral directors as necessary; and (iv)
                  to researchers, if certain conditions regarding the privacy of
                  your Health Information have been met.
                </li>
                <li>
                  <b>Workers' Compensation.</b> We may disclose your Health
                  Information to comply with workers' compensation laws and
                  other similar programs that provide benefits for work-related
                  injuries or illnesses.
                </li>
                <li>
                  <b>
                    Disclosures to the Secretary of the U.S. Department of
                    Health and Human Services.
                  </b>{" "}
                  We may be required to disclose your Health Information to the
                  Secretary of the U.S. Department of Health and Human Services
                  to investigate or determine our compliance with HIPAA.
                </li>
                <li>
                  <b>Other Uses and Disclosures.</b> Disclosures and uses of
                  your Health Information that are not described above, such as
                  in a disaster relief situation, or in communication with
                  certain family, friends, or others, may be made by us only
                  with your written authorization. You may revoke your
                  authorization at any time by notifying us by sending an e-mail
                  to{" "}
                  <a href="mailto:privacy.officer@bettermedics.org">
                    Privacy.Officer@BetterMedics.org
                  </a>
                  .
                </li>
              </ul>
            </Block>
            <Block>
              <h2>II. BETTERMEDICS' OBLIGATIONS</h2>
              <p>
                <u>We have the following obligations:</u>
              </p>
              <ul>
                <li>
                  <b>Protect Your Health Information.</b> We are required by law
                  to maintain the privacy and security of your Health
                  Information. Health Information includes all individually
                  identifiable health information transmitted or maintained by
                  us that relates to your past, present or future health,
                  treatment, or payment for health care services.
                </li>
                <li>
                  <b>Provide and Abide by Terms of This Notice.</b> We are
                  required by law to provide you with notice of our legal duties
                  and privacy practices with respect to your Health Information.
                  We must abide by the terms of the Notice that is currently in
                  effect, and must provide you with a copy of this Notice upon
                  request.
                </li>
                <li>
                  <b>Notify You of a Breach.</b> We must notify you in the event
                  that we (or a Business Associate) commits or discovers a
                  breach of unsecured Health Information.
                </li>
                <li>
                  <b>Limit Disclosure of Your Health Information.</b> We will
                  not use or share your information other than as described here
                  unless you tell us we can in writing. If you tell us we can,
                  you may change your mind at any time. Let us know in writing
                  if you change your mind.
                </li>
              </ul>
            </Block>
            <Block>
              <h2>III. YOUR RIGHTS AND CHOICES</h2>
              <p>
                <u>
                  The following summarizes your rights with respect to your
                  Health Information:
                </u>
              </p>
              <ul>
                <li>
                  <b>
                    Right to Inspect and Request a Copy of Your Health
                    Information.
                  </b>{" "}
                  You have the right to inspect and request an electronic or
                  paper copy of your Health Information. Under certain limited
                  circumstances, we may deny your access to a portion of your
                  records. For example, you do not have a right to inspect and
                  receive a copy of information that we have collected in
                  connection with, or in reasonable anticipation of, any legal
                  claim or proceeding. If you request copies of your Health
                  Information, we may charge you reasonable copying or mailing
                  costs and use best efforts to provide such information within
                  thirty (30) days.
                </li>
                <li>
                  <b>Right to Request Restrictions of Uses and Disclosures.</b>{" "}
                  You have the right to request a restriction or limitation on
                  the use or disclosure of your Health Information by us for
                  treatment, payment or health care operations. You also have
                  the right to request a restriction or limitation on the
                  disclosure of your Health Information to someone who is
                  involved in your care or the payment for your care, such as a
                  family member or friend. In your request, you must tell us:
                  (i) what information you want to limit; (ii) whether you want
                  to limit our use, disclosure, or both; and (iii) to whom you
                  want the limits to apply, for example, disclosures to your
                  spouse. In most cases, we are not required to agree to your
                  request for any restriction or limitation on the use or
                  disclosure of your Health Information. However, if we do agree
                  to your request, we are bound by such agreement, except when
                  otherwise required by law, in emergencies, or when the
                  information is necessary to treat you. We must agree to your
                  request for a limitation or restriction on the disclosure of
                  your Health Information to a health plan if you pay for a
                  service or health care item out-of-pocket in full and the
                  disclosure is exclusively for the purpose of payment or
                  operations and not required by law. In addition, if you pay
                  for a service or health care item out-of-pocket in full, you
                  can ask us not to share that information for the purpose of
                  payment with your health insurer.
                </li>
                <li>
                  <b>Right to Request Confidential Communications.</b> You have
                  the right to request that we communicate with you about your
                  Health Information in a certain way or at a certain location.
                  For example, you can request that we only contact you at work
                  or by e-mail. We will accommodate all reasonable requests.
                </li>
                <li>
                  <b>Right to Amend Your Health Information.</b> You have the
                  right to request an amendment of your Health Information that
                  we maintain if you believe that the information is inaccurate
                  or incomplete. We may deny your request if your Health
                  Information is accurate and complete or if the law does not
                  permit us to amend the requested information. We cannot amend
                  information created by your doctor or any person other than a
                  BetterMedics provider. If we say no to your request we will
                  provide a reason in writing within sixty (60) days of your
                  request.
                </li>
                <li>
                  <b>
                    Right to Receive an Accounting of Disclosures of Your Health
                    Information.
                  </b>{" "}
                  You have the right to request an accounting of disclosures we
                  have made of your Health Information during the six (6) years
                  prior to the date of your request, to whom and explanation of
                  the purpose. However, you will not receive an accounting of:
                  (i) disclosures made to you; (ii) disclosures made pursuant to
                  your authorization; (iii) disclosures made for purposes of
                  treatment, payment, or health care operations; and (iv)
                  disclosures made to friends or family in your presence or
                  because of an emergency. Certain other disclosures are also
                  excepted from the HIPAA accounting requirements. If you
                  request more than one accounting in any twelve (12) month
                  period, we may charge you a reasonable fee for each accounting
                  after the first accounting statement.
                </li>
                <li>
                  <b>Right to Revoke your Authorization.</b> If you authorize us
                  to use or disclose your Health Information, you may revoke
                  that authorization, in writing, at any time by mail at the
                  address provided below or by e-mailing us at{" "}
                  <a href="mailto:privacy.officer@bettermedics.org">
                    Privacy.Officer@BetterMedics.org
                  </a>
                  . Once we receive your written revocation, it will only be
                  effective for future uses and disclosures. It will not be
                  effective for any information that may have been used or
                  disclosed in reliance upon the written authorization prior to
                  receiving your written revocation.
                </li>
                <li>
                  <b>Right to Receive a Paper Copy of this Notice.</b> You have
                  the right to receive a paper copy of this Notice upon request,
                  even if you agreed to receive this Notice electronically. To
                  obtain a paper copy of this Notice, contact the Privacy
                  Officer at <a href="tel:2162738400">(216) 273-8400</a>.
                </li>
                <li>
                  <b>File a Complaint.</b> You can complain if you feel we have
                  violated your privacy rights by contacting us or the U.S.
                  Department of Health and Human Services using the information
                  provided below. We will not retaliate against you for filing a
                  complaint.
                </li>
                <li>
                  <b>Choose a Representative.</b> If you have given someone
                  medical power of attorney or if someone is your legal
                  guardian, that person can exercise your rights and make
                  choices about your health information. We will make sure the
                  person has this authority and can act for you before we take
                  any action relating to your health treatment, payment, and
                  related action.
                </li>
              </ul>
            </Block>
            <Block>
              <h2>IV. CHANGES TO THE NOTICE</h2>
              <p>
                We reserve the right to change or update this Notice from time
                to time and make such changes applicable for all Health
                Information that we maintain. When we update the Notice, we will
                revise the “Effective Date" above and post the new Notice at{" "}
                <a href="/privacy-practices">
                  www.bettermedics.org/privacy-practices
                </a>
                . We recommend that you review the Notice each time you visit
                the BetterMedics website or app to stay informed of our privacy
                practices. A copy of this Notice will be available upon request.
              </p>
            </Block>
            <Block>
              <h2>V. COMPLAINTS</h2>
              <p>
                If you believe that we have violated your HIPAA privacy rights,
                you may submit a complaint to BetterMedics or to the Secretary
                of the U.S. Department of Health and Human Services. Complaints
                to BetterMedics can be submitted via website at{" "}
                <a href="/HIPAA-complaints">
                  www.bettermedics.org/HIPAA-Complaints
                </a>{" "}
                or can be sent to{" "}
                <a href="mailto:privacy.officer@bettermedics.org">
                  Privacy.Officer@BetterMedics.org
                </a>
                , call <a href="tel:2162738400">(216) 273-8400</a>, or by mail
                at:
              </p>
              <p>
                <address>
                  BetterMedics, Inc.
                  <br />
                  13001 Cedar Road
                  <br />
                  Cleveland Heights, Ohio 44118
                </address>
              </p>
              <p>Complaints to the Secretary should be sent to:</p>
              <p>
                <address>
                  U.S. Department of Health and Human Services
                  <br />
                  Office for Civil Rights
                  <br />
                  200 Independence Avenue S.W.
                  <br />
                  Washington D.C. 20201
                </address>
              </p>
              <p>
                by visiting{" "}
                <a
                  href="https://www.hhs.gov/ocr/privacy/hipaa/complaints"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.hhs.gov/ocr/privacy/hipaa/complaints
                </a>
                , or by calling <a href="tel:+18776966775">1-877-696-6775</a>.
                BetterMedics will not penalize you or retaliate against you for
                filing a complaint.
              </p>
            </Block>
            <Block>
              <h2>VI. QUESTIONS</h2>
              <p>
                If you have any questions about this Notice or our practices,
                contact our Privacy Officer at{" "}
                <a href="mailto:privacy.officer@bettermedics.org">
                  Privacy.Officer@BetterMedics.org
                </a>
                , call <a href="tel:2162738400">(216) 273-8400</a>, or by mail
                at:
              </p>
              <p>
                <address>
                  BetterMedics, Inc.
                  <br />
                  Attn: Privacy Officer
                  <br />
                  13001 Cedar Road
                  <br />
                  Cleveland Heights, Ohio 44118
                </address>
              </p>
            </Block>
          </Content>
        </Row>
      </Section>
    </Container>
  );
};

const Title = styled.h1`
  margin-bottom: 20px;
`;

const Date = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.textColors.tertiary};
  margin-bottom: 50px;
`;

const Container = styled.div`
  position: relative;
  max-width: ${(props) => (props.legal ? "initial" : "1200px")};
  margin: 0 auto;
  background-color: white;

  p,
  li {
    line-height: 1.8;
    font-size: 15px;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.secondary};
  }

  ol {
    margin-bottom: 40px;
  }

  li {
    color: ${(props) => props.theme.textColors.secondary};
    padding-left: 10px;
    margin-bottom: 10px;
  }

  ${({ legal }) =>
    legal &&
    `
    max-width: initial;

    section {
      padding: 40px;
    }

    ${Row} {
      max-width: initial;
      width: 100%;
    }

    ${Title} {
      font-size: 36px;
    }

    h2 {
      font-size: 26px;
    }
  `}
`;

const Section = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
`;

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  width: calc(100% - 64px);

  @media (max-width: 59.9375em) {
    margin: 0 auto;
    max-width: 500px;
  }
`;

const Content = styled.div`
  position: relative;
  flex: 0 1 auto;
`;

const Block = styled.div`
  margin: 20px 0;
  padding-bottom: 10px;

  p {
    margin-bottom: 30px;
  }

  strong {
    padding-right: 10px;
  }

  ul {
    margin-bottom: 30px;
  }

  address {
    display: block;
    margin-top: 20px;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export default PrivacyPractices;
