import React from "react";
import styled from "styled-components";
import Container from "../../common/Container";
import FAQsList from "../../common/FAQsList";

import wave from "../../../images/home/main-gray-wave.png";

const FAQs = () => (
  <Section id="FAQs">
    <Container>
      <Content>
        <h2>Frequently Asked Questions</h2>
        <FAQsList gray />
      </Content>
    </Container>
  </Section>
);

const Section = styled.section`
  background-color: ${(props) => props.theme.backgroundColors.gray};

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-80%);
    content: "";
    display: block;
    width: 100%;
    height: 135px;
    background-image: url(${wave});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
    pointer-events: none;
  }

  h2 {
    font-size: 46px;
    margin-bottom: 80px;
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    h2 {
      font-size: 36px;
    }
  }
`;

const Content = styled.div`
  padding: 90px 0 110px;
`;

export default FAQs;
