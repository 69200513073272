export const POSITIONS = {
  careLineOperator: {
    title: 'Care Line Operator',
    location: 'Remote On-Call',
    description: '<p>BetterMedics is seeking Care Line Operators to work remotely via our proprietary telehealth platform. In this position, you will support the coordination of care efforts provided by a virtual  interdisciplinary care team (VICT) in order to meet the needs of the BetterMedics patient population. This is primarily a remote and on-call position, however the Care Line Operator will be required to assist with patient on-boarding which includes an in-home patient visit and BetterMedics kit delivery. This position provides generous flexibility and a team-coverage method for scheduling work hours. Applicants should be comfortable with answering care line calls, documenting care intake notes and assisting clinicians to complete a comprehensive virtual medical examination.</p>',
    responsibilities: '<p>In this role you will be required to answer video calls from patients, assist them with taking their vitals, chart notes about the call and connect them with the next line of care. This process is very similar to the intake process used in face-to-face outpatient environments. In addition to calls, we expect the Care Line Operator to follow up with the patients in order to make sure all of their healthcare needs are met. You will also coordinate with clinicians, community health resources, pharmacies, etc. as needed. The goals of this position are to prevent unnecessary ER visits and serve as a proactive layer of care within the home; thereby achieving better health outcomes and increasing quality of life for patients.</p>',
    duties: [
      'Assuring delivery of quality care to all patients in a respectful and professional manner.',
      'Document patient care by charting in patient records within the BetterMedics platform.',
      'Maintain a safe and clean home work environment by complying with procedures, rules, and regulations.',
      'Protect patients and employees by adhering to HIPAA compliance policies and protocols.',
    ],
    hours: '<p>This is a salary position, where the Care Line Operator is expected to be “on call and available” during their scheduled hours.</p><p>Operators will each have three 24 hour shifts during the week. During this time the Operator must be able to answer calls within 2 minutes. Since calls are taken from the home, the Operator has flexibility to engage in home activities, as long as the Care Line is answered.</p>',
    compensation: [
      {
        label: 'Job Type',
        value: 'Salary',
      },
      {
        label: 'Medical Benefits',
        value: 'None',
      },
      {
        label: 'Schedule',
        value: 'On-call 72 hours per week. (Three 24 hour shifts.) Must be prepared to take urgent calls within 2 minutes of contact.',
      },
      {
        label: 'Certification',
        value: 'None required.',
      },
      {
        label: 'Experience',
        value: 'Experience in 911 call center or conducting intake within a medical office preferred.',
      },
      {
        label: 'Work Location',
        value: 'Remote On-Call; In-Home patient visits will occur within the first 6 weeks of the contract. After BetterMedics kits are deployed to patients, 100% of the work occurs remotely.',
      },
      {
        label: 'Competencies, Knowledge and Skills',
        value: [
          'Intermediate proficiency level with web-based tools including email, Microsoft Office, including Outlook, Word and Excel',
          'Ability to communicate effectively with a diverse group of individuals',
          'Ability to multitask and work independently within a team environment',
          'Ability to remain alert for long shifts',
          'Ability to work quickly and efficiently',
          'Strong advocate for patients at all levels of care',
          'Critical listening and thinking skills',
          'Decision making and problem solving skills',
          'Strong organizational and time management skills',
        ],
      },
    ],
    link: 'https://docs.google.com/forms/d/e/1FAIpQLSfg6Wt28jyIdjjM7OaqvqE46mp8Xmuspb0UyniQIHadJ71MgQ/viewform?usp=sf_link',
  },
  // physicianEmployee: {
  //   title: 'Physician - Employee',
  //   location: 'Fully Remote',
  //   description: '<p>BetterMedics is seeking an experienced Physician to provide patient care via our proprietary telehealth platform.  This position is an on-call, remote job opportunity, where pay is provided even if the Physician does not take a call.  This position provides generous flexibility and a team-coverage method for scheduling work hours. The ideal candidate must have an active license in the state of <b>Ohio.</b></p>',
  //   perks: [
  //     'Work remotely! Flexible scheduling with 8-hour shift blocks, running 24/7. Ability to schedule up to 24 hours consecutively.',
  //     'Get paid for on-call time, not just the time that you take patient calls.',
  //     'Bonus structure implemented for call volume.',
  //   ],
  //   responsibilities: '<p>Your primary job is to answer video calls from patients, resulting in a succinct care plan and/or connect the patient to the next line of care. In addition to care calls, we expect clinicians to follow up with the patients in order to make sure all of their healthcare needs are met. You will coordinate with other providers, community health resources, pharmacies, etc. as needed. The goals of this position are to prevent unnecessary ER visits and serve as a proactive layer of care within the home; thereby achieving better health outcomes and increasing quality of life for patients. Most of your active time will be spent within the BetterMedics platform on video calls with patients.</p>',
  //   duties: [
  //     'Assuring delivery of quality care to all patients in a respectful and professional manner.',
  //     'Document patient care by charting in patient records within the BetterMedics platform.',
  //     'Perform therapeutic procedures by ordering treatments and prescribing medications.',
  //     'Instruct and counsel patients by describing therapeutic regimens; providing counseling on emotional problems of daily living; promoting wellness and health maintenance.',
  //     'Provide continuity of care by developing and implementing patient management plans.',
  //     'Maintain a safe and clean home work environment by complying with procedures, rules, and regulations.',
  //     'Protect patients and employees by adhering to HIPAA compliance policies and protocols.',
  //     'Comply with federal, state, and local legal and professional requirements.',
  //     'Maintain professional and technical knowledge by attending educational workshops; reviewing professional publications; establishing personal networks; and participating in professional societies.',
  //     'Contribute to the team effort by accomplishing related tasks as needed.',
  //   ],
  //   compensation: [
  //     {
  //       label: 'Job Type',
  //       value: 'Hourly + Bonus: Employee',
  //     },
  //     {
  //       label: 'Pay',
  //       value: '$50 per hour plus bonus incentive for call volume',
  //     },
  //     {
  //       label: 'Benefits',
  //       value: 'None',
  //     },
  //     {
  //       label: 'Schedule',
  //       value: '8 hour - 24 hour shift blocks running 24/7',
  //     },
  //     {
  //       label: 'License/Capabilities',
  //       value: 'Ohio: State Certified Medical Doctor (Required)',
  //     },
  //     {
  //       label: 'Work Location',
  //       value: 'Fully Remote',
  //     },
  //     {
  //       label: 'Competencies, Knowledge and Skills',
  //       value: [
  //         'Intermediate proficiency level with Electronic Health Records (EHR), web-based tools including email, Microsoft Office, including Outlook, Word and Excel',
  //         'Ability to communicate effectively with a diverse group of individuals',
  //         'Ability to multi-task and work independently within a team environment',
  //         'Knowledge of local, state & federal healthcare laws and regulations & all company policies regarding case management practices',
  //         'Adhere to code of ethics that aligns with professional practice',
  //         'Strong advocate for patients at all levels of care',
  //         'Awareness of community & state support resources',
  //         'Critical listening and thinking skills',
  //         'Decision making and problem solving skills',
  //         'Strong organizational and time management skills',
  //       ],
  //     },
  //   ],
  //   link: 'https://docs.google.com/forms/d/e/1FAIpQLSe5QSc39xSOwfpIwJeQlNzyCaF8wDb6J15kqX4oXZHpa6otQA/viewform?usp=sf_link',
  // },
  physicianContractor: {
    title: 'Physician - Contractor',
    location: 'Fully Remote',
    description: '<p>BetterMedics is seeking an experienced Physician to provide patient care via our proprietary telehealth platform.  This position is an on-call, remote job opportunity, where pay is provided even if the Physician does not take a call.  This position provides generous flexibility and a team-coverage method for scheduling work hours. The ideal candidate must have an active license in the state of <b>Ohio.</b></p>',
    perks: [
      '<b>Pay:</b> $20 per hour for on-call time plus $125 per active call.',
      'Work remotely! Flexible scheduling with 12-hour shift blocks, running 24/7. Ability to schedule up to 24 hours consecutively.',
      'Get paid for on-call time, not just the time that you take patient calls.',
    ],
    responsibilities: '<p>Your primary job is to answer video calls from patients, resulting in a succinct care plan and/or connect the patient to the next line of care. In addition to care calls, we expect clinicians to follow up with the patients in order to make sure all of their healthcare needs are met. You will coordinate with other providers, community health resources, pharmacies, etc. as needed. The goals of this position are to prevent unnecessary ER visits and serve as a proactive layer of care within the home; thereby achieving better health outcomes and increasing quality of life for patients. Most of your active time will be spent within the BetterMedics platform on video calls with patients.</p>',
    duties: [
      'Assuring delivery of quality care to all patients in a respectful and professional manner.',
      'Document patient care by charting in patient records within the BetterMedics platform.',
      'Perform therapeutic procedures by ordering treatments and prescribing medications.',
      'Instruct and counsel patients by describing therapeutic regimens; providing counseling on emotional problems of daily living; promoting wellness and health maintenance.',
      'Provide continuity of care by developing and implementing patient management plans.',
      'Maintain a safe and clean home work environment by complying with procedures, rules, and regulations.',
      'Protect patients and employees by adhering to HIPAA compliance policies and protocols.',
      'Comply with federal, state, and local legal and professional requirements.',
      'Maintain professional and technical knowledge by attending educational workshops; reviewing professional publications; establishing personal networks; and participating in professional societies.',
      'Contribute to the team effort by accomplishing related tasks as needed.',
    ],
    compensation: [
      {
        label: 'Job Type',
        value: 'Hourly & Per Call: Contractor',
      },
      {
        label: 'Pay',
        value: '$20 per hour for on-call time plus $125 per active call',
      },
      {
        label: 'Benefits',
        value: 'None',
      },
      {
        label: 'Schedule',
        value: '12 hour - 24 hour shift blocks running 24/7',
      },
      {
        label: 'License/Capabilities',
        value: 'Ohio License, Medical Doctor or Doctor of Osteopathic Medicine',
      },
      {
        label: 'Work Location',
        value: 'Fully Remote',
      },
      {
        label: 'Competencies, Knowledge and Skills',
        value: [
          'Intermediate proficiency level with Electronic Health Records (EHR), web-based tools including email, Microsoft Office, including Outlook, Word and Excel',
          'Ability to communicate effectively with a diverse group of individuals',
          'Ability to multi-task and work independently within a team environment',
          'Knowledge of local, state & federal healthcare laws and regulations & all company policies regarding case management practices',
          'Adhere to code of ethics that aligns with professional practice',
          'Strong advocate for patients at all levels of care',
          'Awareness of community & state support resources',
          'Critical listening and thinking skills',
          'Decision making and problem solving skills',
          'Strong organizational and time management skills',
        ],
      },
    ],
    link: 'https://docs.google.com/forms/d/e/1FAIpQLSeBzZWMH5IjnDaIXVhtpRl_tBht97WRdOaYTlOkXxq-hpFeCA/viewform?usp=sf_link',
  },
};
