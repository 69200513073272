import React from "react";
import styled from "styled-components";
import { MdCheckCircle } from "react-icons/md";
import Button from "../components/common/Button";
import Column from "../components/common/Column";
import Container from "../components/common/Container";
import Row from "../components/common/Row";
import HowItWorks from "../components/sections/partners/HowItWorks";

import paging from "../images/partners/paging.webp";

const Partners = () => (
  <Page id="partners">
    <Hero>
      <Container>
        <Content>
          <h1>The Next Generation of Telehealth</h1>
          <p>
            We are a healthcare provider and a tech company, so we have several
            partnership pathways.
          </p>
        </Content>
      </Container>
    </Hero>
    <Benefits>
      <Container>
        <Content>
          <Row gap={80} mobileGap={40}>
            <Column align="right">
              <Providers>
                <h2>Healthcare Provider</h2>
                <p>We're excited to collaborate with payors to:</p>
                <BulletsWrapper>
                  <Bullet>
                    <MdCheckCircle />
                    <span>Provide a new layer of care in the home</span>
                  </Bullet>
                  <Bullet>
                    <MdCheckCircle />
                    <span>Augment existing care teams</span>
                  </Bullet>
                  <Bullet>
                    <MdCheckCircle />
                    <span>Improve patient care</span>
                  </Bullet>
                </BulletsWrapper>
              </Providers>
            </Column>
            <Column align="left">
              <Tech>
                <h2>Telehealth Solution</h2>
                <p>
                  We serve as a turnkey solution for practices and hospital
                  systems interested in telehealth.
                </p>
                <p>
                  Our system was designed to meet the unique needs of complex
                  patients.
                </p>
              </Tech>
            </Column>
          </Row>
        </Content>
      </Container>
    </Benefits>
    <HowItWorks />
    <Copy>
      <Container>
        <Content>
          <Group>
            <Row valign="center" gap={80} mobileGap={40} reverseMobile>
              <Column align="left" width="55%">
                <h2>Flexible Practice Model</h2>
                <p>
                  We tackled call workflow with a modern-day paging system, so
                  staff can be on-call without having to be logged-in. This
                  feature was especially important for our Physicians.
                </p>
                <p>
                  We can also add a range of care professionals as a medical
                  practice evolves - from triage nurses to case managers and
                  physician specialists.
                </p>
              </Column>
              <Column width="45%" align="center">
                <PagingImage src={paging} alt="" />
              </Column>
            </Row>
          </Group>
        </Content>
      </Container>
    </Copy>
    <ContactUs>
      <Container>
        <Content>
          <h3>Begin the Conversation</h3>
          <p>
            Contact us to share your care objectives and learn how we can help
            you realize a new model of care.
          </p>
          <Button
            btnTheme="primaryText"
            href="https://docs.google.com/forms/d/e/1FAIpQLScARWdx8_B-1vjHx5Zk8LP-K_x1h9dunEDVBI_fRUIPtf4HXQ/viewform"
            target="_blank"
            styles={{ minWidth: "200px", borderRadius: "8px" }}
          >
            Contact us
          </Button>
        </Content>
      </Container>
    </ContactUs>
  </Page>
);

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const Section = styled.section``;
const Content = styled.div``;

const Hero = styled(Section)`
  ${Content} {
    padding: 80px 0 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
      margin-bottom: 20px;
    }
    p {
      font-size: 20px;
    }
  }
`;

const ContactUs = styled(Section)`
  margin-top: 80px;
  ${Content} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: ${(props) => props.theme.backgroundColors.tertiary};
    padding: 80px;
    border-radius: 15px;

    h3 {
      font-size: 30px;
      text-align: center;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      max-width: 480px;
      margin-bottom: 40px;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0 !important;
    margin-top: 0;

    ${Content} {
      padding: 60px 40px;
      border-radius: 0;
      p {
        font-size: 17px;
      }
    }
  }
`;

const Copy = styled(Section)`
  ${Content} {
    padding: 80px 0 0;

    p:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }

  @media only screen and (max-width: 768px) {
    ${Content} {
      padding: 80px 0;
    }
  }
`;

const Group = styled.div`
  margin: 120px 0;

  p {
    font-size: 18px;
    line-height: 1.6;
    color: ${(props) => props.theme.textColors.primary};
    margin-bottom: 0;
    padding-right: 50px;
  }

  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 60px;
  }

  @media only screen and (max-width: 768px) {
    margin: 80px 0;

    &:last-of-type {
      margin-bottom: 0;
    }

    h3 {
      font-size: 30px;
    }

    p {
      font-size: 18px;
      line-height: 1.4;
      text-align: center;
      padding-right: 0;
    }
  }
`;

const Benefits = styled(Section)`
  margin-bottom: 80px;
`;

const BenefitsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 50px;
  border-radius: 40px;

  height: 100%;
  min-height: 364px;
  width: 100%;
  max-width: 500px;

  @media only screen and (max-width: 768px) {
    border-radius: 20px;
    padding: 40px;
    min-height: initial;
  }
`;

const Providers = styled(BenefitsBox)`
  background-color: ${(props) => props.theme.backgroundColors.tertiary};

  ${Content} {
    padding: 80px 0;

    p {
      font-size: 19px;
    }
  }

  @media only screen and (max-width: 768px) {
    ${Content} {
      padding: 50px 0;
    }
  }
`;

const Tech = styled(BenefitsBox)`
  background-color: ${(props) => props.theme.backgroundColors.green};

  p:last-of-type {
    margin-bottom: 0;
  }
`;

const BulletsWrapper = styled.div`
  padding-left: 20px;
  padding-top: 20px;

  @media only screen and (max-width: 768px) {
    padding-left: 4px;
    padding-top: 4px;
  }
`;

const Bullet = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 26px;

  &:last-of-type {
    margin-bottom: 0;
  }

  svg {
    font-size: 26px;
    color: ${(props) => props.theme.textColors.primary};
  }

  span {
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
    flex: 1;
    color: ${(props) => props.theme.textColors.primary};
  }

  @media only screen and (max-width: 768px) {
    gap: 10px;
    span {
      font-size: 16px;
    }
    svg {
      font-size: 24px;
    }

    &:last-of-type {
      margin-bottom: 6px;
    }
  }
`;

const PagingImage = styled.img`
  display: block;
  object-fit: contain;
  max-width: 360px;
  width: 100%;
`;

export default Partners;
