import React, { useState } from "react";
import styled from "styled-components";
import Container from "../../common/Container";
import Column from "../../common/Column";
import Row from "../../common/Row";

import call from "../../../images/partners/telehealth-call.webp";
import concierge from "../../../images/partners/concierge-onboarding.webp";
import medicalKit from "../../../images/partners/medical-devices.webp";
import wave from "../../../images/home/main-hero-wave.png";

const STEPS = [
  {
    id: "onboarding",
    title: "Concierge Onboarding",
    description: "We spend quality time onboarding patients into the system.",
    image: concierge,
  },
  {
    id: "devices",
    title: "Medical Device Training",
    description:
      "During onboarding each patient is taught how to use the medical devices.",
    image: medicalKit,
  },
  {
    id: "calls",
    title: "Human Touch",
    description:
      "When a patient calls, they are immediately connected to a CareLine Operator - never a chat bot.",
    image: call,
  },
];

const HowItWorks = () => {
  const [activeBlock, setActiveBlock] = useState(0);

  return (
    <Section>
      <Container>
        <Content>
          <Row>
            <Column align="center">
              <ImageWrapper>
                {STEPS[activeBlock].image ? (
                  <img src={STEPS[activeBlock].image} alt="" />
                ) : null}
              </ImageWrapper>
            </Column>
            <Column align="left">
              <h2>Patient-Centric Design</h2>
              <p>
                Telehealth can feel overwhelming and impersonal. The
                BetterMedics platform was designed with Physicians and Patients
                to be an organic care experience.
              </p>
              <StepsWrapper>
                {STEPS.map((item, index) => (
                  <Block
                    key={item.id}
                    $active={index === activeBlock}
                    onMouseOver={() => setActiveBlock(index)}
                  >
                    <Number>{index + 1}</Number>
                    <TextWrapper>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </TextWrapper>
                  </Block>
                ))}
              </StepsWrapper>
            </Column>
          </Row>
        </Content>
      </Container>
    </Section>
  );
};

const Section = styled.section`
  background: ${(props) => props.theme.backgroundColors.beige};

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100px;
    background-image: url(${wave});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    transform: scaleX(-100%) rotate(180deg);
    pointer-events: none;
  }
`;

const Content = styled.div`
  padding: 140px 0 100px;

  h2 {
    font-size: 40px;
  }
  p {
    line-height: 1.5;
  }
  img {
    width: 400px;
    max-height: 480px;
    object-fit: contain;
  }

  @media only screen and (max-width: 768px) {
    padding: 140px 0 80px;

    h2 {
      font-size: 32px;
      text-align: center;
    }
    p {
      text-align: center;
    }
    img {
      display: none;
    }
  }
`;

const Number = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  font-size: 28px;
  font-family: ${(props) => props.theme.fonts.primary};
  color: ${(props) => props.theme.textColors.primary};

  @media only screen and (max-width: 768px) {
    border: 1px dashed ${(props) => props.theme.textColors.primary};
  }
`;

const TextWrapper = styled.div`
  flex: 1;
`;

const Block = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 30px;
  border-radius: 20px;
  background-color: transparent;
  width: 100%;
  min-height: 120px;
  gap: 20px;
  cursor: pointer;

  h4 {
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 20px 15px;

    p {
      text-align: left;
    }
  }

  @media only screen and (min-width: 769px) {
    ${({ $active, theme }) =>
      $active &&
      `
		background-color: white;
		box-shadow: ${`0 12px 40px rgba(${theme.backgroundColors.stoneRGB}, 0.2)`};
		${Number} {
			background-color: ${theme.textColors.primary};
			color: white;
		}
	`}
  }
`;

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
  margin-left: -25px;
  padding-right: 30px;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    padding-right: 0;
  }
`;

const ImageWrapper = styled.div`
	padding-top: 60px;

  @media only screen and (max-width: 768px) {
		padding-top; 0;
    display: none;
  }
`;

export default HowItWorks;
