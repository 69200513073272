import React from "react";
import styled from "styled-components";
import Container from "../../common/Container";
import Column from "../../common/Column";

const Hero = () => (
  <Section id="hero">
    <Container>
      <Content>
        <Column align="center" valign="middle">
          <h1>Our Devices</h1>
          <p>
            Use the videos below to learn how to use your BetterMedics medical
            devices.
          </p>
        </Column>
      </Content>
    </Container>
  </Section>
);

const Section = styled.section`
  background: white;

  h1 {
    font-size: 56px;
    margin-bottom: 20px;
    line-height: 1.1;
  }

  p {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 30px;
    max-width: 500px;
    text-align: center;
  }
`;

const Content = styled.div`
  padding: 100px 0;
`;

export default Hero;
