import logo from "../images/bettermedics-logo.png";

import React from "react";
import styled from "styled-components";

const ErrorPage = () => (
  <Container>
    <a href="/">
      <Logo src={logo} />
    </a>
    <Content>
      <h1>Page Not Found.</h1>
      <p>
        There was no page found at this address. Please check your URL and try
        again.
      </p>
      <Button href="/">Go Home</Button>
    </Content>
  </Container>
);

const Button = styled.a`
  display: block;
  width: 180px;
  height: 48px;
  line-height: 48px;
  background: linear-gradient(215deg, #ffaa76, #f9566e);
  border-radius: 6px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  color: white;
  letter-spacing: 0.5px;
  margin-top: 24px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: linear-gradient(195deg, #ffaa76, #f9566e);
  }

  @media only screen and (max-width: 550px) {
    align-self: center;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  padding: 30px;
`;

const Content = styled.div`
  flex: 1;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 250px;

  @media only screen and (max-width: 550px) {
    width: 200px;
    align-self: center;
  }
`;

export default ErrorPage;
