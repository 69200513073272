import React, { useMemo, useState } from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import { darken } from "polished";
import { MdClose } from "react-icons/md";

import { QUESTIONS } from "../../faqs";

const FAQsList = ({ data, gray }) => {
  const [active, setActive] = useState();

  const onToggleActive = (key) => {
    if (key === active) setActive();
    else setActive(key);
  };

  const listData = useMemo(() => {
    return data || Object.keys(QUESTIONS);
  }, [data]);

  return (
    <Wrapper>
      {listData.map((key) => (
        <Item key={key} active={active === key}>
          <QuestionBtn gray={gray} onClick={() => onToggleActive(key)}>
            <label>{QUESTIONS[key].question}</label>
            <MdClose />
          </QuestionBtn>
          <Answer gray={gray}>
            <AnswerContent>{parse(QUESTIONS[key].answer)}</AnswerContent>
          </Answer>
        </Item>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 0 80px;
`;

const QuestionBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  border-bottom: 1px solid
    ${(props) =>
      props.gray
        ? `rgba(${props.theme.textColors.secondaryRGB}, 0.3)`
        : props.theme.colors.border};
  padding: 25px 15px;

  label {
    pointer-events: none;
    flex: 1;
    font-size: 18px;
    line-height: 28px;
    color: ${(props) =>
      props.gray
        ? props.theme.textColors.primaryDark
        : props.theme.textColors.primary};
  }

  svg {
    pointer-events: none;
    font-size: 24px;
    color: ${(props) => props.theme.textColors.primary};
    transition: transform 0.15s ease-in;
    transform: rotate(45deg);
  }

  &:hover {
    background-color: ${(props) =>
      props.gray
        ? darken(0.01, props.theme.backgroundColors.gray)
        : "rgba(190,190,190,0.05)"};

    label,
    svg {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

const Item = styled.div`
  ${({ active, theme }) =>
    active &&
    `
		${QuestionBtn} {
			background-color: ${darken(0.01, theme.backgroundColors.gray)};
				
			label {
				font-weight: bold;
			}
			> svg {
				transform: rotate(0deg);
				
			}
		}

		${Answer} {
			max-height: 100vh;
		}
	`}
`;

const Answer = styled.div`
  transition: max-height 0.3s ease-in;
  max-height: 0px;
  overflow: hidden;

  p {
    line-height: 1.6;
  }

  p,
  ol,
  li {
    font-size: 18px;
    color: ${(props) => props.theme.textColors.primary};
  }

  li {
    margin-bottom: 16px;
  }
`;

const AnswerContent = styled.div`
  padding: 20px 15px;
`;

export default FAQsList;
