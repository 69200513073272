import React from "react";
import styled from "styled-components";
import Button from "../../common/Button";
import Container from "../../common/Container";
import Row from "../../common/Row";

import caregivers from "../../../images/home/caregivers.jpg";
import complexPatients from "../../../images/home/complex-patients.jpg";
import wave from "../../../images/home/main-hero-wave.png";

const DATA = [
  {
    id: "patients",
    title: "For Complex Patients",
    description:
      "We take the guesswork out of telehealth. Having your vitals collected means you can receive better care from the comfort of your home.",
    image: complexPatients,
    btn: {
      link: "/patients",
      label: "Learn More",
    },
  },
  {
    id: "caregivers",
    title: "For Caregivers",
    description:
      "We specialize in serving complex patients, so we understand the challenges you face. We provide clinical support to help you care for your loved ones.",
    image: caregivers,
    btn: {
      link: "/caregivers",
      label: "Learn More",
    },
  },
];

const CareForThoseYouLove = () => (
  <Section id="care-for-those-you-love">
    <Container>
      <Content>
        <h2>Care for you and those you love</h2>
        <Row gap={40}>
          {DATA.map((item) => (
            <Item key={item.id} id={item.id}>
              <img src={item.image} alt={item.title} />
              <h4>{item.title}</h4>
              <p>{item.description}</p>
              <div>
                <Button href={item.btn.link} size="small">
                  {item.btn.label}
                </Button>
              </div>
            </Item>
          ))}
        </Row>
      </Content>
    </Container>
  </Section>
);

const Section = styled.section`
  background: ${(props) => props.theme.backgroundColors.beige};

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100px;
    background-image: url(${wave});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    transform: scaleX(-100%) rotate(180deg);
    pointer-events: none;
  }
`;

const Content = styled.div`
  padding: 180px 0 120px;

  h2 {
    font-size: 46px;
    margin-bottom: 48px;
    line-height: 1.1;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 150px;

    h2 {
      font-size: 36px;
      text-align: center;
    }
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  img {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 20px;
    object-fit: contain;
  }

  h4 {
    font-size: 20px;
    font-family: ${(props) => props.theme.fonts.secondary};
    font-weight: bold;
    margin-bottom: 17px;
  }

  p {
    font-size: 16px;
    margin-bottom: 17px;
  }

  @media only screen and (max-width: 768px) {
    text-align: center;

    h4 {
      margin-bottom: 12px;
    }
  }
`;

export default CareForThoseYouLove;
