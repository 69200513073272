import React from "react";
import styled from "styled-components";

import Footer from "../components/Footer";
import Header from "../components/Header";

const MainLayout = ({ children }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const isWebView = queryParameters.get("webview");

  return (
    <Container>
      {isWebView ? null : <Header />}

      <Content>{children}</Content>
      {isWebView ? null : <Footer mode="internal" />}
    </Container>
  );
};

const Content = styled.div`
  flex: 1;

  section {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: visible;
    padding: 0 40px;
  }

  @media only screen and (max-width: 768px) {
    section {
      padding: 0 20px;
    }
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export default MainLayout;
