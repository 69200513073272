import React from "react";
import styled from "styled-components";
import Container from "../../common/Container";
import Column from "../../common/Column";
import Row from "../../common/Row";

const VideoSection = ({ title, description, cover, videoID }) => (
  <Section>
    <Container>
      <Content>
        <Row gap={90} mobileGap={40}>
          <Column align="left" width="45%">
            <h3>{title}</h3>
            {description ? <p>{description}</p> : null}
          </Column>
          <Column width="55%">
            {videoID ? (
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${videoID}?rel=0&modestbranding=1`}
                title={title}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            ) : cover ? (
              <Image src={cover} />
            ) : null}
          </Column>
        </Row>
      </Content>
    </Container>
  </Section>
);

const Section = styled.section`
  background: white;

  h3 {
    font-size: 40px;
    margin-bottom: 14px;
    line-height: 1.3;
  }

  p {
    font-size: 22px;
    line-height: 1.5;
  }

  li {
    color: ${(props) => props.theme.textColors.secondary};
    margin-bottom: 12px;
    font-size: 20px;
  }

  @media only screen and (max-width: 768px) {
    h3 {
      font-size: 28px;
      text-align: center;
    }
  }
`;

const Content = styled.div`
  padding: 120px 0;
  border-top: 2px dashed ${(props) => props.theme.colors.border};

  iframe {
    max-width: 100%;
  }

  @media only screen and (max-width: 768px) {
    padding: 60px 0;

    iframe {
      max-height: 220px;
    }
  }
`;

const Image = styled.img`
  display: block;
  object-fit: contain;
  max-width: 100%;
  overflow: hidden;
`;

export default VideoSection;
