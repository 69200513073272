import React from "react";
import styled from "styled-components";

/**
 *
 * @param {String} align defaults to center, converts "left" to "flex-start" and "right" to "flex-end"
 * @param {String} valign defaults to center, converts "top" to "flex-start" and "bottom to "flex-end"
 * @param {Integer} gap space between items
 * @param {Boolean} rowOnMobile preserve row on mobile -- otherwise converts to column
 * @param {String} mobileAlign defaults to null, overrides "align" for mobile
 * @param {Integer} mobileGap space for mobile only
 * @param {Boolean} reverseMobile change order
 * @returns
 */

const Row = ({
  align = "center",
  valign = "center",
  gap = 0,
  mobileAlign,
  rowOnMobile,
  mobileGap,
  reverseMobile,
  children,
}) => (
  <Wrapper
    $align={align}
    $valign={valign}
    $gap={gap}
    $rowOnMobile={rowOnMobile}
    $mobileAlign={mobileAlign}
    $mobileGap={mobileGap}
    $reverseMobile={reverseMobile}
  >
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.$align === "left"
      ? "flex-start"
      : props.$align === "right"
      ? "flex-end"
      : props.$align};
  align-items: ${(props) =>
    props.$valign === "top"
      ? "flex-start"
      : props.$valign === "bottom"
      ? "flex-end"
      : props.$valign};
  width: 100%;
  gap: ${(props) => `${props.$gap}px`};

  @media only screen and (max-width: 768px) {
    flex-direction: ${(props) =>
      props.$rowOnMobile
        ? props.$reverseMobile
          ? "row-reverse"
          : "row"
        : props.$reverseMobile
        ? "column-reverse"
        : "column"};

    ${({ $mobileGap }) =>
      $mobileGap &&
      `
      gap: ${$mobileGap}px;
    `}

    ${({ $mobileAlign }) =>
      $mobileAlign &&
      `
      align-items: ${
        $mobileAlign === "left"
          ? "flex-start"
          : $mobileAlign === "right"
          ? "flex-end"
          : $mobileAlign
      };
    `}
  }
`;

export default Row;
