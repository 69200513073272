import React from "react";
import styled from "styled-components";
import Button from "./Button";
import Container from "./Container";
import Column from "./Column";
import Row from "./Row";

const wave = require("../../images/home/main-hero-wave.png");

const Hero = ({ title, description, btnLabel, image, onClick }) => (
  <Section id="hero">
    <Container>
      <Content>
        <Row mobileGap={50}>
          <Column align="left" width="50%">
            <h1>{title}</h1>
            <p>{description}</p>
            <Button onClick={onClick}>{btnLabel}</Button>
          </Column>
          <Column width="50%">{image ? <Image src={image} /> : null}</Column>
        </Row>
      </Content>
    </Container>
  </Section>
);

const Section = styled.section`
  background: rgb(254, 252, 246);
  background: linear-gradient(
    120deg,
    rgba(244, 235, 222, 1) 0%,
    rgba(247, 240, 230, 1) 100%
  );

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100px;
    background-image: url(${wave});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    pointer-events: none;
  }

  h1 {
    font-size: 56px;
    margin-bottom: 20px;
    line-height: 1.1;
  }

  p {
    font-size: 24px;
    padding-right: 40px;
    line-height: 1.5;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 768px) {
    text-align: center;

    h1 {
      font-size: 36px;
    }
    p {
      font-size: 18px;
      padding-right: 0;
    }
  }
`;

const Content = styled.div`
  padding: 60px 0 100px;
`;

const Image = styled.img`
  display: block;
  object-fit: contain;
  max-width: 100%;
`;

export default Hero;
