import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import parse from "html-react-parser";
import { POSITIONS } from "../data/Positions";

const convertToCamelCase = (str) => {
  return str.replace(/-([a-z])/g, (g) => {
    return g[1].toUpperCase();
  });
};

const Position = () => {
  const { id } = useParams();
  const key = convertToCamelCase(id);
  const data = POSITIONS[key];

  useEffect(() => {
    if (!id || (key && !data)) window.location = "/404";
  }, [id, key, data]);

  return (
    <Container>
      <Section>
        {data ? (
          <Row>
            <Content>
              <Breadcrumbs>
                <a href="/careers">Careers</a>
                <span style={{ padding: "0 10px" }}>{"//"}</span>
                <span>{data.title}</span>
              </Breadcrumbs>
              <h1>{data.title}</h1>
              <h3>{data.location}</h3>
              <Block>
                {/* <h4>{data.title}</h4> */}
                {parse(data.description)}
              </Block>
              {data.perks ? (
                <Block>
                  <h4>Perks</h4>
                  <ul>
                    {data.perks.map((perk) => (
                      <li key={perk}>{parse(perk)}</li>
                    ))}
                  </ul>
                </Block>
              ) : null}
              <Block>
                <h4>Responsibilities</h4>
                {parse(data.responsibilities)}
                <Indent>
                  <h5>Additional duties include</h5>
                  <ol>
                    {data.duties.map((duty) => (
                      <li key={duty}>{duty}</li>
                    ))}
                  </ol>
                </Indent>
              </Block>
              {data.hours ? (
                <Block>
                  <h4>Work Hours</h4>
                  {parse(data.hours)}
                </Block>
              ) : null}

              <Block>
                <h4>Compensation &amp; Position Details</h4>
                {data.compensation.map((item) => (
                  <div key={item.label}>
                    {Array.isArray(item.value) ? (
                      <>
                        <p>
                          <strong>{item.label}:</strong>
                        </p>
                        <ul>
                          {item.value.map((bullet) => (
                            <li key={bullet}>{bullet}</li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <p>
                        <strong>{item.label}:</strong>
                        {item.value}
                      </p>
                    )}
                  </div>
                ))}
              </Block>
              <ApplyBtn href={data.link} target="_blank">
                Apply Now
              </ApplyBtn>
            </Content>
            <Sidebar>
              <SideBarContent>
                <h3>Why BetterMedics?</h3>
                <p>
                  BetterMedics is a driver of innovative home care delivery for
                  our nation’s most vulnerable population. We exist at the
                  intersection of cutting-edge technology and compassionate
                  healthcare. Our team is passionate about improving the lives
                  of our patients and driving value for our partners. We are
                  looking for individuals who share our excitement about
                  creating a better future for our patients and acting as a
                  change agent in the healthcare industry.
                </p>
              </SideBarContent>
            </Sidebar>
          </Row>
        ) : null}
      </Section>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;

  h3 {
    font-size: 24px;
    font-weight: 400;
  }

  h4,
  h5 {
    margin-top: 30px;
  }

  p,
  li {
    line-height: 1.8;
  }

  ol {
    margin-bottom: 40px;
  }

  li {
    color: ${(props) => props.theme.textColors.secondary};
    padding-left: 10px;
    margin-bottom: 10px;
  }
`;

const Section = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;

  @media (min-width: 1200px) {
    padding-bottom: 110px;
    padding-top: 110px;
  }
`;

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  width: calc(100% - 64px);

  @media (max-width: 59.9375em) {
    margin: 0 auto;
    max-width: 500px;
  }
`;

const Content = styled.div`
  position: relative;
  flex: 0 1 auto;

  h3 {
    margin-bottom: 60px;
  }

  @media (min-width: 59.9375em) {
    margin-bottom: 64px;
  }

  @media (min-width: 60em) {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (min-width: 80em) {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
    padding-left: 40px;
    padding-right: 40px;
  }
`;

const Sidebar = styled.div`
  position: relative;
  flex: 0 1 auto;
  padding-top: 96px;

  @media (min-width: 60em) {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
    margin-left: 8.33333%;
  }

  @media (min-width: 80em) {
    flex-basis: 25%;
    max-width: 25%;
  }
`;

const SideBarContent = styled.div`
  background-color: #f6f8fa;
  padding: 25px;
`;

const Breadcrumbs = styled.div`
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 18px;

  a {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;

    &:hover {
      color: ${(props) => props.theme.colors.secondary};
    }
  }
  span {
    color: ${(props) => props.theme.textColors.secondary};
  }
`;

const Block = styled.div`
  padding-bottom: 10px;

  strong {
    padding-right: 10px;
  }
`;

const Indent = styled.div`
  padding-left: 30px;
`;

const ApplyBtn = styled.a`
  margin-top: 36px;
  display: inline-block;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  cursor: pointer;
  height: 48px;
  line-height: 48px;
  border-radius: 50px;
  padding: 0 60px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.25px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;

export default Position;
